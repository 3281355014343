import * as React from "react";

function ErrorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 460 460"
      xmlSpace="preserve"
      width={72} 
      height={72}
    >
      <path d="M230 6C106.5 6 6 106.5 6 230s100.5 224 224 224 224-100.5 224-224S353.5 6 230 6zm0 412c-103.7 0-188.1-84.3-188.1-188S126.3 41.9 230 41.9 418 126.3 418 230s-84.3 188-188 188z" />
      <path d="m312 164.1-23-23-64 64-64-64-23 23 64 64-64 64 23 22.6 64-64 64 64 23-22.6-64-64 64-64z" />
    </svg>
  );
}

export default ErrorIcon;
