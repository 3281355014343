import { get, post } from "./base.service";
import { Session, PurchaseData, PurchaseRequest, PurchaseResponse } from "../store/config/types";
import { endpoints } from "./endpoints";

export const purchaseService = {
  getPurchaseData,
  purchase,
  makePurchase,
  generatePaymentLink
};

async function getPurchaseData({ sessionId }: Session): Promise<PurchaseData | undefined> {
  return await get<PurchaseData>(`${endpoints["getPurchaseData"]}/${sessionId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function purchase({ sessionId }: Session): Promise<{ isSuccess: boolean; error: string } | undefined> {
  return await get<any>(`${endpoints["purchase"]}/${sessionId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function makePurchase({ sessionId, fingerprint }: PurchaseRequest): Promise<PurchaseResponse | undefined> {
  return await post<PurchaseResponse | undefined>(`${endpoints["purchase"]}`, { sessionId, fingerprint })
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function generatePaymentLink(amount: number, secret: string): Promise<undefined> {
  const input = {
    alphaCurrency: 'UYU',
    amount
  }

  return await post<undefined>(`${endpoints["generatePaymentLink"]}`, { input }, {
    method: 'post',
    headers: { "Content-Type": "application/json", "merchant-secret-key": secret },
    body: JSON.stringify(input)
  })
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}