import { PurchaseData, Instrument, Issuer, Transaction, CognitoUserResponse, TransactionCallbackResponse } from "./types";

export const DEFAULT = "DEFAULT";

export interface DefaultAction {
  type: typeof DEFAULT;
}

//#region Load Purchase Data

export const LOADING_PURCHASE_DATA = "LOADING_PURCHASE_DATA";
export const LOAD_PURCHASE_DATA_SUCCESS = "LOAD_PURCHASE_DATA_SUCCESS";
export const LOAD_PURCHASE_DATA_FAILED = "LOAD_PURCHASE_DATA_FAILED";

export interface LoadingPurchaseDataAction {
  type: typeof LOADING_PURCHASE_DATA;
  sessionId: string;
}

export interface LoadPurchaseDataSuccessAction {
  type: typeof LOAD_PURCHASE_DATA_SUCCESS;
  data: PurchaseData;
}

export interface LoadPurchaseDataFailedAction {
  type: typeof LOAD_PURCHASE_DATA_FAILED;
  error: string;
}

export type PurchaseDataTypes =
  | LoadingPurchaseDataAction
  | LoadPurchaseDataSuccessAction
  | LoadPurchaseDataFailedAction;

//#endregion

//#region Purchase

export const PURCHASING = "PURCHASING";
export const PURCHASE_SUCCESS = "PURCHASE_SUCCESS";
export const PURCHASE_FAILED = "PURCHASE_FAILED";
export const TRANSACTION_CALLBACK = "TRANSACTION_CALLBACK";
export const REGISTER_CALLBACK_FAILED = "REGISTER_CALLBACK_FAILED";
export const PAGE_REDIRECTED = "PAGE_REDIRECTED";
export const GENERATE_PAYMENT_LINK = "GENERATE_PAYMENT_LINK";

export interface PurchasingAction {
  type: typeof PURCHASING;
}

export interface PurchaseSuccessAction {
  type: typeof PURCHASE_SUCCESS;
  transactionCallbackResponse: TransactionCallbackResponse;
}

export interface PurchaseFailedAction {
  type: typeof PURCHASE_FAILED;
  error: string;
}

export type RegisterCallbackFailedType = {
  type: typeof REGISTER_CALLBACK_FAILED;
  response: boolean;
};

export type TransactionCallbackType = {
  type: typeof TRANSACTION_CALLBACK;
  //do we need any more info?
};

export type PageRedirectedType = {
  type: typeof PAGE_REDIRECTED;
  sessionId: string;
}

export type GeneratePaymentLinkType = {
  type: typeof GENERATE_PAYMENT_LINK;
  amount: Number;
  secret: String;
  callback: any;
}

//#endregion

export type PurchaseTypes =
  | PurchasingAction
  | PurchaseSuccessAction
  | PurchaseFailedAction
  | RegisterCallbackFailedType
  | TransactionCallbackType
  | PageRedirectedType;

//#region Get Plexo URL

export const GETTING_URL = "GETTING_URL";
export const GET_URL_SUCCESS = "GET_URL_SUCCESS";
export const GET_URL_ERROR = "GET_URL_ERROR";

export interface GettingUrlAction {
  type: typeof GETTING_URL;
}

export interface GetUrlSuccessAction {
  type: typeof GET_URL_SUCCESS;
  url: string;
  sessionId: string;
}

export interface GetUrlErrorAction {
  type: typeof GET_URL_ERROR;
  error: string;
}

export type GetUrlTypes = GettingUrlAction | GetUrlSuccessAction | GetUrlErrorAction;

//#endregion

//#region Get instrument

export const GETTING_INSTRUMENT = "GETTING_INSTRUMENT";
export const GET_INSTRUMENT_SUCCESS = "GET_INSTRUMENT_SUCCESS";
export const GET_INSTRUMENT_ERROR = "GET_INSTRUMENT_ERROR";
export const CLEAR_INSTRUMENT = "CLEAR_INSTRUMENT";

export interface GettingInstrumentAction {
  type: typeof GETTING_INSTRUMENT;
}

export interface GetInstrumentSuccessAction {
  type: typeof GET_INSTRUMENT_SUCCESS;
  instrument: Instrument;
}

export interface GetInstrumentErrorAction {
  type: typeof GET_INSTRUMENT_ERROR;
  error: string;
}

export interface ClearInstrumentAction {
  type: typeof CLEAR_INSTRUMENT;
}

export type GetInstrumentTypes =
  | GettingInstrumentAction
  | GetInstrumentSuccessAction
  | GetInstrumentErrorAction
  | ClearInstrumentAction;

//#endregion

//#region  Get issuers

export const GETTING_ISSUERS = "GETTING_ISSUERS";
export const GET_ISSUERS_SUCCESS = "GET_ISSUERS_SUCCESS";
export const GET_ISSUERS_ERROR = "GET_ISSUERS_ERROR";

export interface GettingIssuersAction {
  type: typeof GETTING_ISSUERS;
}

export interface GetIssuersSuccessAction {
  type: typeof GET_ISSUERS_SUCCESS;
  issuers: Issuer[];
}

export interface GetIssuersErrorAction {
  type: typeof GET_ISSUERS_ERROR;
  error: string;
}

export type GetIssuersTypes = GettingIssuersAction | GetIssuersSuccessAction | GetIssuersErrorAction;

//#endregion

export type InstrumentTypes = GetInstrumentTypes | GetIssuersTypes;

//#region Cognito Signup

export const SIGNINGUP = "SIGNINGUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";

export interface SigningUpAction {
  type: typeof SIGNINGUP;
}

export interface SignUpSuccessAction {
  type: typeof SIGNUP_SUCCESS;
}

export interface SignUpErrorAction {
  type: typeof SIGNUP_ERROR;
  error: string;
}

export type SignUpTypes = SigningUpAction | SignUpSuccessAction | SignUpErrorAction;

//#endregion

//#region  Confirm user

export const CONFIRMING_USER = "CONFIRMING_USER";
export const CONFIRM_USER_SUCCESS = "CONFIRM_USER_SUCCESS";
export const CONFIRM_USER_ERROR = "CONFIRM_USER_ERROR";

export interface ConfirmingUserAction {
  type: typeof CONFIRMING_USER;
}

export interface ConfirmUserSuccessAction {
  type: typeof CONFIRM_USER_SUCCESS;
  code: string;
}

export interface ConfirmUserErrorAction {
  type: typeof CONFIRM_USER_ERROR;
  error: string;
}

export type ConfirmUserTypes = ConfirmingUserAction | ConfirmUserSuccessAction | ConfirmUserErrorAction;

//#endregion

//#region  Cognito Login

export const LOGGINGIN = "LOGGINGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export interface LoggingInAction {
  type: typeof LOGGINGIN;
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  userId: string;
  user: CognitoUserResponse;
}

export interface LoginErrorAction {
  type: typeof LOGIN_ERROR;
  error: string;
}

export type LoginTypes = LoggingInAction | LoginSuccessAction | LoginErrorAction;

//#endregion

//#region  Cognito udpate user

export const UPDATING_USER = "UPDATING_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export interface UpdatingUserAction {
  type: typeof UPDATING_USER;
}

export interface UpdateUserSuccessAction {
  type: typeof UPDATE_USER_SUCCESS;
  email: string;
  name: string;
}

export interface UpdateUserErrorAction {
  type: typeof UPDATE_USER_ERROR;
  error: string;
}

export type UpdateUserTypes = UpdatingUserAction | UpdateUserSuccessAction | UpdateUserErrorAction;

//#endregion

//#region  Cognito password

export const RESETTING_PASSWORD = "RESETTING_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export interface ResettingPasswordAction {
  type: typeof RESETTING_PASSWORD;
}

export interface ResetPasswordSuccessAction {
  type: typeof RESET_PASSWORD_SUCCESS;
}

export interface ResetPasswordErrorAction {
  type: typeof RESET_PASSWORD_ERROR;
  error: string;
}

export type ResetPasswordTypes = ResettingPasswordAction | ResetPasswordSuccessAction | ResetPasswordErrorAction;

//#endregion

//#region  Cognito submit reset password

export const SUBMITTING_PASSWORD = "SUBMITTING_PASSWORD";
export const SUBMIT_PASSWORD_SUCCESS = "SUBMIT_PASSWORD_SUCCESS";
export const SUBMIT_PASSWORD_ERROR = "SUBMIT_PASSWORD_ERROR";

export interface SubmittingPasswordAction {
  type: typeof SUBMITTING_PASSWORD;
}

export interface SubmitPasswordSuccessAction {
  type: typeof SUBMIT_PASSWORD_SUCCESS;
}

export interface SubmitPasswordErrorAction {
  type: typeof SUBMIT_PASSWORD_ERROR;
  error: string;
}

export type SubmitPasswordTypes = SubmittingPasswordAction | SubmitPasswordSuccessAction | SubmitPasswordErrorAction;

//#endregion

//#region  Cognito change password

export const CHANGING_PASSWORD = "CHANGING_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export interface ChangingPasswordAction {
  type: typeof CHANGING_PASSWORD;
}

export interface ChangePasswordSuccessAction {
  type: typeof CHANGE_PASSWORD_SUCCESS;
}

export interface ChangePasswordErrorAction {
  type: typeof CHANGE_PASSWORD_ERROR;
  error: string;
}

export type ChangePasswordTypes = ChangingPasswordAction | ChangePasswordSuccessAction | ChangePasswordErrorAction;

//#endregion

export type CognitoTypes =
  | SignUpTypes
  | ConfirmUserTypes
  | UpdateUserTypes
  | LoginTypes
  | ResetPasswordTypes
  | SubmitPasswordTypes
  | ChangePasswordTypes;

//#region Fetch transactions

export const LOADING_TRANSACTIONS = "LOADING_TRANSACTIONS";
export const ADD_TRANSACTIONS = "ADD_TRANSACTIONS";
export const TRANSACTIONS_ERROR = "TRANSACTIONS_ERROR";

export interface LoadingTransactionsAction {
  type: typeof LOADING_TRANSACTIONS;
}

export interface AddTransactionsAction {
  type: typeof ADD_TRANSACTIONS;
  transactions: Transaction[];
}

export interface FetchTransactionsError {
  type: typeof TRANSACTIONS_ERROR;
  error: string;
}

export type FetchTransactionsTypes = LoadingTransactionsAction | AddTransactionsAction | FetchTransactionsError;

//#endregion

export type TransactionsTypes = FetchTransactionsTypes;

export type AppActions = PurchaseDataTypes | PurchaseTypes | GetUrlTypes | GetInstrumentTypes | CognitoTypes | GeneratePaymentLinkType;
