export enum Currency {
  PESO = 858,
  DOLAR = 840,
}

export enum PaymentStatusEnum {
  CREATED = 0,
  SUCCESS = 1,
  ERROR = 2,
  PENDING = 3,
}

export enum PaymentStatusSpanishEnum {
  CREADO = 0,
  FINALIZADO = 1,
  FRACASO = 2,
  PENDIENTE = 3,
}

export enum DateFilterEnum {
  WEEK = 0,
  MONTH,
  LAST_MONTH,
  LAST_6_MONTHS,
}

export enum CheckMerchantErrorCodes {
  INVALID_MERCHANT = 1,
  DISABLED_MERCHANT = 2,
  CHECK_MERCHANT_FAILED = 3,
  INVALID_MERCHANT_PLEXO_ID = 4,
  INVALID_ERROR_CODE = 5,
  MERCHANT_ID_IS_NOT_A_GUID = 6,
}

export enum ErrorCodes {
  INVALID_LINK = 1,
  EXPIRED_LINK = 2,
  ALREADY_USED_LINK = 3,
  INVALID_MERCHANT_PLEXO_ID = 4,
  CHECK_LINK_FAILED = 5,
  INVALID_STATUS = 6,
  NO_CONTENT = 100,
  DATABASE_ERROR = 300,
  UNKNOWN_ERROR = 500,
  ARGUMENT_ERROR = 600,
  INVALID_SESSION = 800,
  INSTRUMENT_NOT_FOUND = 801,
  PLEXO_PAYMENT_ERROR = 2001,
  PLEXO_AUTHORIZATION_ERROR = 2002,
}

export enum CognitoErrorCodes {
  INVALID_PARAMETERS = "InvalidParameterException",
  USER_EXISTS = "UsernameExistsException",
  NOT_AUTHORIZED = "NotAuthorizedException",
  EXPIRED_CODE = "ExpiredCodeException",
}
