import React from "react";
import { FeedbackBox } from "../../components/common/FeedbackBox";
import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { RootState } from "../../store/config/types";
import { connect, ConnectedProps } from "react-redux";
import { urlVarsHelper } from "../../helpers/urlVarsHelper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      alignItems: "flex-start",
      minHeight: "100vh",
      height: "100%",
      justifyContent: "center",
      background: '#f0f0f0',
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        padding: 10
      }
    },
  })
);

const mapStateToProps = (state: RootState) => ({
  purchases: state.purchases,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function ResultScreen({ purchases }: PropsFromRedux) {
  const classes = useStyles();

  let isCard: boolean = false;
  let errorCode: string;
  let urlVars = urlVarsHelper.getUrlVars();

  if (urlVars.isCard) {
    isCard = urlVars.isCard === "true" ? true : false;
  }

  errorCode = decideErrorCode(isCard);

  return (
    <div className={classes.mainContainer}>
      <FeedbackBox
        errorCode={errorCode}
        purchaseData={purchases?.purchaseData}
        transactionCallbackResponse={purchases.transactionCallbackResponse}
        showActions={true}
      />
    </div>
  );

  function decideErrorCode(isCard: boolean): string {
    let errorCode = (isCard ? "0" : "7");
    errorCode = purchases.transactionCallbackResponse?.issuer === "Pago después" ? "12" : errorCode;
    errorCode = purchases.transactionCallbackResponse?.issuer === "Sistarbanc" ? "13" : errorCode;
    return errorCode;
  }
}

export default connector(ResultScreen);
