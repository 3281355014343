import { createTheme } from "@mui/material/styles";

export const colors = {
  primary: "#6E28FA",
  success: "#74B765",
  error: "#B54444",
  primaryBg: "#F5F5F5",
  primaryContrast: "#FFF",
  primaryBorderColor: "#E2E2E2",
  darkTxtColor: "#5D5D5D",
  lightTxtColor: "#AEAEAE",
  coloredTxtColor: "#BB9AFF",
  linkColor: "#0077FF",
  grayLight: "#666666",
  grayDark: "#333333"
};

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.linkColor,
    },
    success: {
      main: colors.success,
    },
    error: {
      main: colors.error,
    },
    background: {
      default: colors.primaryBg,
    },
    text: {
      primary: colors.lightTxtColor,
      secondary: colors.darkTxtColor,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primaryContrast,
          borderRadius: "10px"
        },
        notchedOutline: {
          borderColor: "transparent"
        }
      }
    }
  },
  typography: {
    fontFamily: [
      "muli",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
    body1: {
      fontSize: 14,
      marginBlockEnd: "1em",
    },
    h1: {
      fontWeight: "normal",
      fontSize: "1.25rem",
      color: colors.primary,
      marginBlockEnd: "0",
    },
    h2: {
      fontWeight: "normal",
      fontSize: "1.25rem",
      color: colors.primary,
      marginBlockEnd: "0",
    },
  },
});