import React, { useState, useEffect, useCallback } from "react";
import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { PartnersFooter } from "../../components/PartnersFooter";
import { PaymentDetail } from "../../components/common/PaymentDetail";
import { PurchaseRequest, RootState, Session } from "../../store/config/types";
import { connect, ConnectedProps } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../store/config/ActionTypes";
import { getPlexoUrl, getActiveIssuers } from "../../store/action_creators/instruments.actions";
import { useHistory } from "react-router-dom";
import { urlVarsHelper } from "../../helpers/urlVarsHelper";
import { fetchPurchaseData } from "../../store/action_creators/purchases.actions";
import { UserInfoHeader } from "../../components/common/UserInfoHeader";
import { v4 as uuid } from "uuid";
import { PaymentStatusEnum } from "../../store/config/enums";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      width: "45%",
      margin: "auto",
      flex: 1,
      display: "flex",
      alignItems: "center",
    },
    dataContainer: {
      height: "429px",
      display: "flex",
    },
    instructionsContainer: {
      width: "46%",
    },
    paymentContainer: {
      width: "54%",
    },
    button: {
      fontSize: 16,
    },
    iFrame: {
      border: 0,
    },
  })
);

const mapStateToProps = (state: RootState) => ({
  cognito: state.cognito,
  purchases: state.purchases,
  instruments: state.instruments,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  getPlexoUrl: ({ sessionId, fingerprint }: PurchaseRequest) => dispatch(getPlexoUrl({ sessionId, fingerprint })),
  fetchPurchaseData: ({ sessionId }: Session) => dispatch(fetchPurchaseData({ sessionId })),
  getIssuers: ({ sessionId }: Session) => dispatch(getActiveIssuers({ sessionId })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function DetailsScreen({
  cognito,
  purchases,
  instruments,
  getPlexoUrl,
  fetchPurchaseData,
  getIssuers
}: PropsFromRedux) {
  const classes = useStyles();
  const history = useHistory();
  const urlVars = urlVarsHelper.getUrlVars();
  const [showConfirmContainer, setShowConfirmContainer] = useState(true);

  //#region fingerprint

  const isTest =
    window.location.host === "localhost:3000" ||
    window.location.host === "handytest.netlify.app" ||
    window.location.host === "handytestwebsite.handsoft.com.uy" ||
    window.location.host === "pago.arriba.uy";
  const environmentId = isTest ? "1snn5n9w" : "k8vif92e";
  const commerceId = isTest ? "123" : "visanetuy_px_2048256716";
  const fingerprintId = uuid();
  const visaFingerprint = commerceId + fingerprintId;

  const loadVisaDeviceFingerprint = (callback: any) => {
    const existingScript = document.getElementById("visaDeviceFingerprint");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = `https://h.online-metrix.net/fp/tags.js?org_id=${environmentId}&session_id=${visaFingerprint}`;
      script.id = "visaDeviceFingerprint";
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    }

    if (existingScript && callback) callback();
  };

  useEffect(() => {
    loadVisaDeviceFingerprint(() => {
      return;
    });
  });

  //#endregion

  const redirectTo = useCallback(
    (path: string): void => {
      history.push(path);
    },
    [history]
  );

  useEffect(() => {
    const receiveMessage = (e: MessageEventInit) => {
      if (e.data === "redirectError") {
        redirectTo("/error/?errorCode=4");
      } else if (e.data === "redirectSuccess") {
        if (
          purchases.transactionCallbackResponse &&
          purchases.transactionCallbackResponse.status === PaymentStatusEnum.PENDING
        ) {
          redirectTo("/result");
        }
      }
    }

    window.addEventListener("message", receiveMessage);

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, [purchases.transactionCallbackResponse, redirectTo]);

  useEffect(() => {
    if (purchases.purchaseSuccess && purchases.transactionCallbackResponse?.status !== PaymentStatusEnum.PENDING) {
      history.push(`/result?isCard=${!instruments.instrument?.notACard}`);
    }

    if (purchases.purchaseError !== null) {
      redirectTo(instruments.instrument?.notACard ? "/error?errorCode=8" : "/error?errorCode=3");
    }
  }, [purchases.purchaseSuccess, purchases.purchaseError, purchases.transactionCallbackResponse, redirectTo, instruments,history]);

  useEffect(() => {
    const continueWithPayment = () => {
      if (purchases.sessionId) {
        getPlexoUrl({
          sessionId: purchases.sessionId,
          fingerprint: isTest ? "d1cab7e203194d09972b7fb3d4657fc8" : fingerprintId,
        });
        setShowConfirmContainer(false);
        window.scrollTo(0, 0);
      }
    };

    if (urlVars.sessionId) {
      if (!purchases.sessionId) {
        fetchPurchaseData({ sessionId: urlVars.sessionId });
      }
      if (
        !instruments.loadingActiveIssuers &&
        instruments.activeIssuers === null &&
        instruments.activeIssuersError === null &&
        purchases.loadPurchaseDataSuccess &&
        purchases.sessionId
      ) {
        getIssuers({ sessionId: purchases.sessionId });
        continueWithPayment();
      }
    } else if (!purchases.sessionId) {
      history.push("/error?errorCode=6");
    }

    if (urlVars.from) {
      if (urlVars.from === "confirm") {
        if (purchases.sessionId) {
          getPlexoUrl({
            sessionId: purchases.sessionId,
            fingerprint: isTest ? "d1cab7e203194d09972b7fb3d4657fc8" : fingerprintId,
          });
        }
        setShowConfirmContainer(false);
        window.scrollTo(0, 0);
      }
    }

    if (cognito.user?.signInUserSession !== undefined) {
      getPlexoUrl({
        sessionId: purchases.sessionId ? purchases.sessionId : "",
        fingerprint: isTest ? "d1cab7e203194d09972b7fb3d4657fc8" : fingerprintId,
      });
      setShowConfirmContainer(false);
    }

    if (purchases.loadPurchaseDataError) {
      history.push("/error?errorCode=99");
    }
  }, [
    purchases.sessionId,
    fetchPurchaseData,
    redirectTo,
    getPlexoUrl,
    purchases.loadPurchaseDataError,
    purchases.loadPurchaseDataSuccess,
    getIssuers,
    cognito.user,
    instruments.activeIssuers,
    instruments.activeIssuersError,
    instruments.loadingActiveIssuers,
    urlVars.from,
    urlVars.sessionId,
    fingerprintId,
    isTest,
    history
  ]);

  useEffect(() => {
    if (instruments.urlError != null) {
      redirectTo("/error/?errorCode=5");
    }
  }, [instruments.urlError, redirectTo]);

  
  // const continueToLogin = () => {
  //   navigate(`/login?sessionId=${purchases.sessionId}`);
  // };

  return (
    <div className="details-screen payment-details-screen">
      {cognito.userId !== null ? <UserInfoHeader profile noBack /> : null}
      <div className="data-container">
        {showConfirmContainer &&
          (cognito.user === null || (cognito.user !== null && cognito.user?.signInUserSession === undefined)) ? (
          <div className="confirm-cat-container">

            {instruments.activeIssuers !== null && <PartnersFooter issuers={instruments.activeIssuers} />}
          </div>
        ) : (
          <div className="iframe-container">
            <iframe
              height={600}
              width={500}
              src={instruments.url ? instruments.url : "/?plexoLoading=true"}
              title="plexoIframe"
              className={classes.iFrame}
            ></iframe>
          </div>
        )}
        <PaymentDetail data={purchases.purchaseData} />
        {showConfirmContainer && (
          <div className="mobile-pay-action-box">
            {instruments.activeIssuers !== null && <PartnersFooter issuers={instruments.activeIssuers} />}
          </div>
        )}
      </div>
    </div>
  );
}

//TODO: Achicar componente

export default connector(DetailsScreen);
