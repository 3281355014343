import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { Error } from "../screens/Error";
import { Details } from "../screens/Details";
import { Result } from "../screens/Result";
import { Loading } from "../screens/Loading";
import { Redirect } from "../components/common/Redirect";
import { Login } from "../screens/Login";
import { UserDetails } from "../screens/UserDetails";
import { Register } from "../screens/Register";
import { ResetPassword } from "../screens/ResetPassword";
import { SubmitPassword } from "../screens/SubmitPassword";
import { ConfirmEmail } from "../screens/ConfirmEmail";
import { CustomToolbar } from "./common/CustomToolbar";
import { Logo } from "./common/Logo";
import { colors } from "../theme";
import Cookies from "universal-cookie";
import { PaymentHistory } from "../screens/PaymentHistory";
import { PaymentDetail } from "../screens/PaymentDetail";
import { RootState } from "../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../store/config/ActionTypes";
import { cognitoAccount } from "../store/action_creators/cognito.actions";
import { connect, ConnectedProps } from "react-redux";
import { urlHelper } from "../helpers/urlHelper";
import SelfCheckout from "../screens/SelfCheckout/SelfCheckout";

const mapStateToProps = (state: RootState) => ({
  cognito: state.cognito,
  purchases: state.purchases,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  cognitoAccount: () => dispatch(cognitoAccount()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function Main({ cognito, purchases, cognitoAccount }: PropsFromRedux) {
  const history = useHistory();
  const [hasHeaderAndFooter, setHasHeaderAndFooter] = useState(!urlHelper.isHeaderlessUrl(history.location.pathname));

  history.listen((location, action) => {
    if (action === "PUSH") {
      setHasHeaderAndFooter(!urlHelper.isHeaderlessUrl(location.pathname));
    }
  });

  useEffect(() => {
    const cookies = new Cookies();

    const hasToken = cookies.get("token") !== undefined;

    if (hasToken && !cognito.loggingIn && cognito.userId === null && cognito.loginError === null) {
      cognitoAccount();
    }

    if (!hasToken && urlHelper.isPrivatePage(history.location.pathname)) {
      history.push("/");
    }

    if (purchases.purchaseData) {
      sessionStorage.setItem('clientSiteUrl', purchases.purchaseData.clientSiteUrl || '');
      sessionStorage.setItem('commerceName', purchases.purchaseData.client.commerceName || '');
    }
  }, [cognito.loggingIn, cognito.userId, cognito.loginError, cognitoAccount, history, purchases.purchaseData]);

  return (
    <div className="page">
      {hasHeaderAndFooter ? <CustomToolbar url={purchases?.purchaseData?.clientSiteUrl} /> : null}
      <div className="main-container">
        <Switch>
          {/* <Route path="/confirm-email" component={ConfirmEmail} /> */}
          <Route path="/details" component={Details} />
          <Route path="/error" component={Error} />
          {/* <Route path="/historic" component={PaymentHistory} /> */}
          {/* <Route path="/login" component={Login} /> */}
          {/* <Route path="/payment-details/:id" component={PaymentDetail} /> */}
          <Route path="/redirect" component={Redirect} />
          {/* <Route path="/register" component={Register} /> */}
          {/* <Route path="/reset-password" component={ResetPassword} /> */}
          {/* <Route path="/submit-password" component={SubmitPassword} /> */}
          <Route path="/result" component={Result} />
          {/* <Route path="/user-details" component={UserDetails} /> */}
          <Route path="/self-checkout" component={SelfCheckout} />
          <Route path="/" component={Loading} />
        </Switch>
      </div>
      {hasHeaderAndFooter ? (
        <div className="screen-feedback-logo">
          <Logo textColor={colors.darkTxtColor} handColor={colors.primary} />
        </div>
      ) : null}
    </div>
  );
}

export default connector(Main);

