import React, { useState } from "react";
import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { colors } from "../../../theme";
import { PurchaseData } from "../../../store/config/types";
import { currencyHelper } from "../../../helpers/currencyHelper";
import { useLottie } from "lottie-react";
import handyLoadAnimation from "../../../assets/handyLoadAnimation.json";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paymentDetailsWrapper: {
      color: colors.darkTxtColor,
    },
    paymentDetailsSummaryContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 40,
      padding: '0 5px 0 10px',
      margin: '20px 20px 0',
      borderBottom: '1px solid rgba(0,0,0,0.2)',

      '& div:nth-child(2)': {
        fontSize: 12,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',

        '& > svg': {
          marginTop: 2,
          transition: 'all 0.2s linear',
          transform: 'rotateZ(180deg)'
        }
      },

      [theme.breakpoints.down('sm')]: {
        margin: '5px 10px 0'
      }
    },
    paymentDetailsSummaryContainerClosed: {
      '& div:nth-child(2)': {
        '& > svg': {
          transform: 'rotateZ(0)'
        }
      }
    },
    paymentDetailsContentContainer: {
      margin: '10px 20px 0',
      borderBottom: '1px solid rgba(0,0,0,0.2)',
      transition: 'all 0.2s linear',
      maxHeight: 0,
      overflow: 'hidden',

      [theme.breakpoints.down('sm')]: {
        margin: '10px 10px 0'
      }
    },
    paymentDetailsContentContainerClosed: {
      marginTop: 0,
      border: 'none',
    },
    paymentDetailsCommerce: {
      background: '#ffffff',
      padding: '5px 11px',
      fontSize: 12,
      borderRadius: 5
    },
    paymentDetailsProduct: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '10px 0',
      margin: '0 10px',
      borderBottom: '1px solid rgba(0,0,0,0.1)',
      fontSize: 12,

      '&:last-child': {
        borderBottom: 'none'
      }
    },
    paymentDetailsTotalContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '0 20px',
      padding: '15px 10px',
      borderBottom: '1px solid rgba(0,0,0,0.1)',
      fontSize: 14,

      '&:last-child': {
        borderBottom: 'none'
      },

      [theme.breakpoints.down('sm')]: {
        margin: '0 10px'
      }
    },
    paymentDetailsTotalContainerNoMargin: {
      margin: 0
    },
    paymentDetailsTotalBoldContainer: {
      fontSize: 18,
      fontWeight: 'bold'
    }
  }),
);

export interface PaymentDetailProps {
  data: PurchaseData | null;
}

function PaymentDetail({ data }: PaymentDetailProps) {
  const classes = useStyles();
  const [paymentDetailsVisible, setPaymentDetailsVisible] = useState(false)
  const paymentDetailsContentMaxHeight = data ? (50 * data.products.length) + 10 + 50 + 30 : 0

  const togglePaymentDetailsVisibility = () => {
    setPaymentDetailsVisible(!paymentDetailsVisible)
  }

  const options = {
    animationData: handyLoadAnimation,
    loop:true,
  };

  const { View } = useLottie(options);

  return (
    <div className={['payment-details-container', paymentDetailsVisible ? 'payment-details-container-open' : null].join(' ')}>
      {data !== null ? (
        <div className="payment-details">
          <div className={classes.paymentDetailsWrapper}>
            <div className={[classes.paymentDetailsSummaryContainer, !paymentDetailsVisible ? classes.paymentDetailsSummaryContainerClosed : null].join(' ')}>
              <div>Detalle: <strong>{data.products?.length}</strong> artículo/s</div>
              <div onClick={togglePaymentDetailsVisibility}>{paymentDetailsVisible ? 'ocultar' : 'mostrar'} <KeyboardArrowDownIcon fontSize="inherit" /></div>
            </div>
            <div
              className={[classes.paymentDetailsContentContainer, !paymentDetailsVisible ? classes.paymentDetailsContentContainerClosed : null].join(' ')}
              style={{ maxHeight: paymentDetailsVisible ? paymentDetailsContentMaxHeight : 0 }}
            >
              <div className={classes.paymentDetailsCommerce}>
                Local/Tienda: <strong>{data.client.commerceName}</strong>
              </div>
              {data.products?.map((product, index) => (
                <div key={index} className={classes.paymentDetailsProduct}>
                  <div><strong>{product.quantity}</strong> {product.name}</div>
                  <div>
                    {currencyHelper.getCurrencyData(data.price.currency).symbol} {(product.amount).toFixed(2)}
                  </div>
                </div>
              ))}
              <div className={[classes.paymentDetailsTotalContainer, classes.paymentDetailsTotalContainerNoMargin].join(' ')}>
                <div>Monto Gravado</div>
                <div>
                  {currencyHelper.getCurrencyData(data.price.currency).symbol} {(data.price.value).toFixed(2)}
                </div>
              </div>
            </div>
            <div className={[classes.paymentDetailsTotalContainer, classes.paymentDetailsTotalBoldContainer].join(' ')}>
              <div>Monto total</div>
              <div>
                {currencyHelper.getCurrencyData(data.price.currency).symbol} {(data.taxedPrice.value).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="payment-details">
          <div className={classes.loadingDiv} data-testid="loading">
            <>{View}</>Cargando
          </div>
        </div>
      )}
    </div>
  )
}

export default PaymentDetail;
