import { Auth } from "aws-amplify";
import {
  ChangePasswordRequest,
  CognitoLoginRequest,
  CognitoSubmitPasswordRequest,
  CognitoUser,
  CognitoUserResponse,
  ConfirmUserRequest,
  UpdateUserRequest,
} from "../store/config/types";

export const cognitoService = {
  signUp,
  confirmSignUp,
  logIn,
  getAccount,
  updateUser,
  resetPassword,
  submitResetPassword,
  changePassword,
};

async function signUp({ username, password, email, name }: CognitoUser) {
  try {
    await Auth.signUp({
      //required by cognito
      username,
      password,
      attributes: {
        //required by Handy
        email,
        name,
      },
    });
  } catch (error: any) {
    throw new Error(error.code);
  }
}

async function confirmSignUp({ username, code }: ConfirmUserRequest) {
  try {
    await Auth.confirmSignUp(username, code);
  } catch (error: any) {
    throw new Error(error.code);
  }
}

async function logIn({ username, password }: CognitoLoginRequest) {
  try {
    const user: CognitoUserResponse = await Auth.signIn(username, password);
    return user;
  } catch (error: any) {
    throw new Error(error.code);
  }
}

async function getAccount() {
  try {
    const user: CognitoUserResponse = await Auth.currentUserInfo();
    return user;
  } catch (error: any) {
    throw new Error(error.code);
  }
}

async function updateUser({ email, name }: UpdateUserRequest) {
  const attributes = {
    email,
    name,
  };

  try {
    await Auth.currentAuthenticatedUser().then(async (user) => {
      await Auth.updateUserAttributes(user, attributes);
    });
  } catch (error: any) {
    throw new Error(error.code);
  }
}

async function resetPassword(username: string) {
  try {
    await Auth.forgotPassword(username);
  } catch (error: any) {
    throw new Error(error.code);
  }
}

async function submitResetPassword({ username, code, new_password }: CognitoSubmitPasswordRequest) {
  try {
    await Auth.forgotPasswordSubmit(username, code, new_password);
  } catch (error: any) {
    throw new Error(error.code);
  }
}

async function changePassword({ oldPassword, newPassword }: ChangePasswordRequest) {
  try {
    await Auth.currentAuthenticatedUser().then(async (user) => {
      await Auth.changePassword(user, oldPassword, newPassword);
    });
  } catch (error: any) {
    throw new Error(error.code);
  }
}
