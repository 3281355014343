import React, { useState } from "react";
import {
  Theme,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { colors } from "../../../theme";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { GoBack } from "../GoBack";
import { useHistory, useLocation, Link } from "react-router-dom";
import { RootState } from "../../../store/config/types";
import { connect, ConnectedProps } from "react-redux";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginBottom: 36,
      [theme.breakpoints.down("xs")]: {
        marginBottom: 16,
      },
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: 4,
    },
    icon: {
      marginRight: "10px",
    },
    link: {
      cursor: "pointer",
      userSelect: "none",
    },
    gap: {
      gap: "1rem",
    },
  })
);

interface UserInfoHeaderProps {
  profile?: boolean;
  historic?: boolean;
  noBack?: boolean;
}

const mapStateToProps = (state: RootState) => ({
  cognito: state.cognito,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PropsType = UserInfoHeaderProps & PropsFromRedux;

function UserInfoHeader({ profile = false, historic = false, noBack = false, cognito }: PropsType) {
  const [logoutOpened, setLogoutOpened] = useState<boolean>(false);

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const closeLogout = () => {
    setLogoutOpened(false);
  };

  const submitLogout = () => {
    const cookies = new Cookies();
    cookies.remove("token", { path: "/" });
    setLogoutOpened(false);
    window.location.reload();
  };

  return (
    <div className={classes.header}>
      <div className={classes.flex}>
        <div className={classes.flex}>
          <AccountCircleOutlinedIcon htmlColor={colors.lightTxtColor} className={classes.icon} />
          <Typography variant="h1">Hola, {cognito.user?.attributes.name}</Typography>
        </div>
        <div className={`${classes.flex} ${classes.gap}`}>
          {profile ? (
            <>
              <Link to="#" className={classes.link} color="secondary" onClick={() => history.push("/user-details")}>
                Perfil
              </Link>
              <Link to="#" className={classes.link} color="secondary" onClick={() => setLogoutOpened(true)}>
                Cerrar sesión
              </Link>
            </>
          ) : (
            <></>
          )}
          {historic ? (
            <Link
              to="#"
              className={classes.link}
              style={{ marginLeft: 16 }}
              color="secondary"
              onClick={() => history.push(`/historic/${location.search}`)}
            >
              Historial de pagos
            </Link>
          ) : (
            <></>
          )}
          {noBack ? (
            <></>
          ) : (
            <GoBack text={location.pathname.startsWith("/payment-details") ? "Volver al listado" : "Volver"} />
          )}
        </div>
      </div>
      <Divider />
      <Dialog open={logoutOpened} onClose={closeLogout}>
        <DialogTitle>Cerrar sesión</DialogTitle>
        <DialogContent>¿Seguro que deseas cerrar sesión?</DialogContent>
        <DialogActions>
          <Button onClick={closeLogout} color="primary">
            Cancelar
          </Button>
          <Button onClick={submitLogout} color="primary">
            Cerrar sesión
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connector(UserInfoHeader);
