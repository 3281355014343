import { PurchaseState } from "../config/types";
import {
  LOADING_PURCHASE_DATA,
  LOAD_PURCHASE_DATA_SUCCESS,
  LOAD_PURCHASE_DATA_FAILED,
  PurchaseDataTypes,
  PURCHASING,
  PURCHASE_SUCCESS,
  PURCHASE_FAILED,
  PurchaseTypes,
  DefaultAction,
  TRANSACTION_CALLBACK,
} from "../config/ActionTypes";

export const initialState: PurchaseState = {
  loadingPurchaseData: false,
  loadPurchaseDataSuccess: false,
  loadPurchaseDataError: null,
  sessionId: null,
  purchaseData: null,
  purchasing: false,
  purchaseSuccess: false,
  transactionCallbackResponse: null,
  purchaseError: null,
  transactionCallbackReceived: false,
};

export function purchasesReducer(
  state = initialState,
  action: PurchaseDataTypes | PurchaseTypes | DefaultAction
): PurchaseState {
  switch (action.type) {
    case LOADING_PURCHASE_DATA:
      window.localStorage.removeItem("clientSiteUrl");
      return {
        ...state,
        loadingPurchaseData: true,
        sessionId: action.sessionId,
      };

    case LOAD_PURCHASE_DATA_SUCCESS:
      const newSessionId = action.data.sessionId || state.sessionId;
      window.localStorage.setItem("clientSiteUrl", action.data.clientSiteUrl || "");
      return {
        ...state,
        sessionId: newSessionId,
        loadingPurchaseData: false,
        loadPurchaseDataError: null,
        loadPurchaseDataSuccess: true,
        purchaseData: action.data,
      };

    case LOAD_PURCHASE_DATA_FAILED:
      window.localStorage.removeItem("clientSiteUrl");
      return {
        ...state,
        loadingPurchaseData: false,
        loadPurchaseDataSuccess: false,
        loadPurchaseDataError: action.error,
      };

    case PURCHASING:
      return {
        ...state,
        purchasing: true,
      };

    case PURCHASE_SUCCESS:
      return {
        ...state,
        purchasing: false,
        purchaseSuccess: true,
        transactionCallbackResponse: action.transactionCallbackResponse,
        transactionCallbackReceived: true,
        purchaseError: null,
      };

    case PURCHASE_FAILED:
      return {
        ...state,
        purchasing: false,
        purchaseSuccess: false,
        purchaseError: action.error,
      };

    case TRANSACTION_CALLBACK:
      return {
        ...state,
        transactionCallbackReceived: true,
      };

    default:
      return state;
  }
}
