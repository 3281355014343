import { Dispatch } from "redux";
import {
  GETTING_URL,
  GET_URL_SUCCESS,
  GET_URL_ERROR,
  GetUrlTypes,
  GETTING_INSTRUMENT,
  GET_INSTRUMENT_SUCCESS,
  GET_INSTRUMENT_ERROR,
  GetInstrumentTypes,
  GETTING_ISSUERS,
  GET_ISSUERS_SUCCESS,
  GET_ISSUERS_ERROR,
  InstrumentTypes,
  CLEAR_INSTRUMENT,
} from "../config/ActionTypes";
import { Instrument, Issuer, PurchaseRequest, Session } from "../config/types";
import { instrumentsService } from "../../services/instruments.service";

export const getPlexoUrl = ({ sessionId, fingerprint }: PurchaseRequest) => {
  return (dispatch: Dispatch<GetUrlTypes>) => {
    dispatch(gettingUrl());
    return instrumentsService.getPlexoUrl({ sessionId, fingerprint }).then(
      (response) => {
        dispatch(addUrl(response, sessionId));
      },
      (error) => {
        dispatch(getUrlFailed(error));
      }
    );
  };
};

export const gettingUrl = (): GetUrlTypes => ({
  type: GETTING_URL,
});

export const addUrl = (url: string, sessionId: string): GetUrlTypes => ({
  type: GET_URL_SUCCESS,
  url,
  sessionId,
});

export const getUrlFailed = (error: string): GetUrlTypes => ({
  type: GET_URL_ERROR,
  error,
});

export const getInstrument = ({ sessionId }: Session) => {
  return (dispatch: Dispatch<GetInstrumentTypes>) => {
    dispatch(gettingInstrument());

    return instrumentsService.getInstrument({ sessionId }).then(
      (response) => {
        dispatch(addInstrument(response!));
      },
      (error) => {
        dispatch(getInstrumentFailed(error));
      }
    );
  };
};

export const gettingInstrument = (): GetInstrumentTypes => ({
  type: GETTING_INSTRUMENT,
});

export const addInstrument = (instrument: Instrument): GetInstrumentTypes => ({
  type: GET_INSTRUMENT_SUCCESS,
  instrument,
});

export const getInstrumentFailed = (error: string): GetInstrumentTypes => ({
  type: GET_INSTRUMENT_ERROR,
  error,
});

export const clearInstrument = (): GetInstrumentTypes => ({
  type: CLEAR_INSTRUMENT,
});

export const getActiveIssuers = ({ sessionId }: Session) => {
  return (dispatch: Dispatch<InstrumentTypes>) => {
    dispatch(gettingIssuer());

    return instrumentsService.getIssuers({ sessionId }).then(
      (response) => {
        dispatch(loadIssuers(response!));
      },
      (error) => {
        dispatch(getIssuersFailed(error));
      }
    );
  };
};

export const gettingIssuer = (): InstrumentTypes => ({
  type: GETTING_ISSUERS,
});

export const loadIssuers = (issuers: Issuer[]): InstrumentTypes => ({
  type: GET_ISSUERS_SUCCESS,
  issuers,
});

export const getIssuersFailed = (error: string): InstrumentTypes => ({
  type: GET_ISSUERS_ERROR,
  error,
});
