import { CognitoState, CognitoUserResponse } from "../config/types";
import {
  SIGNINGUP,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  CONFIRMING_USER,
  CONFIRM_USER_SUCCESS,
  CONFIRM_USER_ERROR,
  CognitoTypes,
  LOGGINGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  RESETTING_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SUBMITTING_PASSWORD,
  SUBMIT_PASSWORD_SUCCESS,
  SUBMIT_PASSWORD_ERROR,
  UPDATING_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  CHANGING_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
} from "../config/ActionTypes";

export const initialState: CognitoState = {
  signingUp: false,
  signUpSuccess: false,
  signUpError: null,
  confirming: false,
  confirmError: null,
  confirmCode: null,
  loggingIn: false,
  loginError: null,
  userId: null,
  user: null,
  updatingUser: false,
  updateUserSuccess: false,
  updateUserError: null,
  resettingPassword: false,
  resetPasswordSuccess: false,
  resetPasswordError: null,
  submittingPassword: false,
  submitPasswordSuccess: false,
  submitPasswordError: null,
  changingPassword: false,
  changePasswordSuccess: false,
  changePasswordError: null,
};

export function cognitoReducer(state = initialState, action: CognitoTypes): CognitoState {
  switch (action.type) {
    case SIGNINGUP: {
      return {
        ...state,
        signingUp: true,
        signUpSuccess: initialState.signUpSuccess,
        signUpError: initialState.signUpError,
      };
    }

    case SIGNUP_SUCCESS: {
      return {
        ...state,
        signingUp: initialState.signingUp,
        signUpSuccess: true,
        signUpError: initialState.signUpError,
      };
    }

    case SIGNUP_ERROR: {
      return {
        ...state,
        signingUp: initialState.signingUp,
        signUpSuccess: initialState.signUpSuccess,
        signUpError: action.error,
      };
    }

    case CONFIRMING_USER: {
      return {
        ...state,
        confirming: true,
        confirmError: initialState.confirmError,
        confirmCode: initialState.confirmCode,
      };
    }

    case CONFIRM_USER_SUCCESS: {
      return {
        ...state,
        confirming: initialState.confirming,
        confirmError: initialState.confirmError,
        confirmCode: action.code,
      };
    }

    case CONFIRM_USER_ERROR: {
      return {
        ...state,
        confirming: initialState.confirming,
        confirmError: action.error,
        confirmCode: initialState.confirmCode,
      };
    }

    case LOGGINGIN: {
      return {
        ...state,
        loggingIn: true,
        loginError: null,
        userId: null,
        user: null,
      };
    }

    case LOGIN_SUCCESS: {
      return {
        ...state,
        loggingIn: false,
        loginError: null,
        userId: action.userId,
        user: action.user,
      };
    }

    case LOGIN_ERROR: {
      return {
        ...state,
        loggingIn: false,
        loginError: action.error,
        userId: null,
        user: null,
      };
    }

    case UPDATING_USER:
      return {
        ...state,
        updatingUser: true,
        updateUserSuccess: false,
        updateUserError: null,
      };

    case UPDATE_USER_SUCCESS:
      if (state.user) {
        let newUser: CognitoUserResponse = {
          ...state.user,
          attributes: {
            ...state.user!.attributes,
            email: action.email,
            name: action.name,
          },
        };

        return {
          ...state,
          updatingUser: false,
          updateUserSuccess: true,
          user: newUser,
          updateUserError: null,
        };
      } else {
        return {
          ...state,
        };
      }

    case UPDATE_USER_ERROR:
      return {
        ...state,
        updatingUser: false,
        updateUserSuccess: false,
        updateUserError: action.error,
      };

    case RESETTING_PASSWORD: {
      return {
        ...state,
        resettingPassword: true,
        resetPasswordSuccess: false,
        resetPasswordError: null,
      };
    }

    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        resettingPassword: false,
        resetPasswordSuccess: true,
        resetPasswordError: null,
      };
    }

    case RESET_PASSWORD_ERROR: {
      return {
        ...state,
        resettingPassword: false,
        resetPasswordSuccess: false,
        resetPasswordError: action.error,
      };
    }

    case SUBMITTING_PASSWORD: {
      return {
        ...state,
        submittingPassword: true,
        submitPasswordSuccess: false,
        submitPasswordError: null,
      };
    }

    case SUBMIT_PASSWORD_SUCCESS: {
      return {
        ...state,
        submittingPassword: false,
        submitPasswordSuccess: true,
        submitPasswordError: null,
      };
    }

    case SUBMIT_PASSWORD_ERROR: {
      return {
        ...state,
        submittingPassword: false,
        submitPasswordSuccess: false,
        submitPasswordError: action.error,
      };
    }

    case CHANGING_PASSWORD: {
      return {
        ...state,
        changingPassword: true,
        changePasswordSuccess: false,
        changePasswordError: null,
      };
    }

    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changingPassword: false,
        changePasswordSuccess: true,
        changePasswordError: null,
      };
    }

    case CHANGE_PASSWORD_ERROR: {
      return {
        ...state,
        changingPassword: false,
        changePasswordSuccess: false,
        changePasswordError: action.error,
      };
    }

    default:
      return state;
  }
}
