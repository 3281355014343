import {
  LOADING_PURCHASE_DATA,
  LOAD_PURCHASE_DATA_SUCCESS,
  LOAD_PURCHASE_DATA_FAILED,
  PurchaseDataTypes,
  PURCHASING,
  PURCHASE_SUCCESS,
  PURCHASE_FAILED,
  PurchaseTypes,
  TransactionCallbackType,
  RegisterCallbackFailedType,
  TRANSACTION_CALLBACK,
  REGISTER_CALLBACK_FAILED,
  PageRedirectedType,
  PAGE_REDIRECTED
} from "../config/ActionTypes";
import { Dispatch } from "redux";
import { PurchaseData, Session, TransactionCallbackResponse } from "../config/types";
import { purchaseService } from "../../services/purchase.service";

//#region Load Purchase Data

export const fetchPurchaseData = ({ sessionId }: Session) => {
  return (dispatch: Dispatch<PurchaseDataTypes>) => {
    dispatch(loadingPurchaseData(sessionId));

    return purchaseService.getPurchaseData({ sessionId }).then(
      (response) => {
        dispatch(loadPurchaseDataSuccess(response!));
      },
      (error: Error) => {
        dispatch(loadPurchaseDataFailed(error.message));
      }
    );
  };
};

export const loadingPurchaseData = (sessionId: string): PurchaseDataTypes => ({
  type: LOADING_PURCHASE_DATA,
  sessionId,
});

export const loadPurchaseDataSuccess = (purchaseData: PurchaseData): PurchaseDataTypes => ({
  type: LOAD_PURCHASE_DATA_SUCCESS,
  data: purchaseData,
});

export const loadPurchaseDataFailed = (error: string): PurchaseDataTypes => ({
  type: LOAD_PURCHASE_DATA_FAILED,
  error,
});

//#endregion

//#region Post Purchse

// export const purchase = ({ sessionId, fingerprint }: PurchaseRequest) => {
//   return (dispatch: Dispatch<PurchaseTypes>) => {
//     dispatch(purchasing());

//     return purchaseService.makePurchase({ sessionId, fingerprint }).then(
//       (response) => {
//         dispatch(purchaseSuccess());
//       },
//       (error) => {
//         dispatch(purchaseFailed(error.message));
//       }
//     );
//   };
// };

export const purchasing = (): PurchaseTypes => ({
  type: PURCHASING,
});

export const purchaseSuccess = (transactionCallbackResponse: TransactionCallbackResponse): PurchaseTypes => ({
  type: PURCHASE_SUCCESS,
  transactionCallbackResponse,
});

export const purchaseFailed = (error: string): PurchaseTypes => ({
  type: PURCHASE_FAILED,
  error,
});

//#endregion

//#region TransactionCallback

export const transactionCallback = (): TransactionCallbackType => ({
  //todo: do we need any more info?
  type: TRANSACTION_CALLBACK,
});

//#endregion

//#region RegisterCallbackFailed

export const registerCallbackFailed = (response: boolean): RegisterCallbackFailedType => ({
  type: REGISTER_CALLBACK_FAILED,
  response,
});

//#endregion

//#region PageRedirect

export const pageRedirect = (sessionId: string): PageRedirectedType => ({
  type: PAGE_REDIRECTED,
  sessionId
})

//#endregion

//#region GeneratePaymentLink

export const generatePaymentLink = (amount: number, secret: string, callback: any) => {
  return () => {
    return purchaseService.generatePaymentLink(amount, secret).then(
      (response: any) => {
        callback(response)
      },
      (error: Error) => {
        callback(null, error.message)
      }
    );
  };
};

//#endregion