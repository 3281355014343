import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface GoBackProps {
  url?: string;
  text?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
      userSelect: "none",
    },
    icon: {
      fontSize: "1.0rem",
      lineHeight: "1.0rem",
      verticalAlign: "middle",
      marginRight: 8,
    },
    link: {
      fontSize: "15px",
      lineHeight: "15px",
    },
  })
);

function GoBack({ url, text }: GoBackProps) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.container}>
      <Link to="#" className={classes.link} color="secondary" onClick={() => (url ? history.push(url) : history.goBack())}>
        <ArrowBackIcon className={classes.icon} color="secondary" />
        {text ?? "Volver"}
      </Link>
    </div>
  );
}

export default GoBack;
