import { BASE_URL } from "./config";

export const endpoints = {
  getPlexoUrl: `${BASE_URL}/payments/confirm`,
  getInstrument: `${BASE_URL}/instruments`,
  getIssuers: `${BASE_URL}/issuers`,
  getPurchaseData: `${BASE_URL}/payments`,
  purchase: `${BASE_URL}/payments/purchase`,
  fetchTransactions: `${BASE_URL}/transactions`,
  generatePaymentLink: `${BASE_URL}/api/payment_qr`
};
