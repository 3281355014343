import { InstrumentsState } from "../config/types";
import {
  GETTING_URL,
  GET_URL_SUCCESS,
  GET_URL_ERROR,
  GetUrlTypes,
  GETTING_INSTRUMENT,
  GET_INSTRUMENT_SUCCESS,
  GET_INSTRUMENT_ERROR,
  GETTING_ISSUERS,
  GET_ISSUERS_SUCCESS,
  GET_ISSUERS_ERROR,
  DefaultAction,
  InstrumentTypes,
  CLEAR_INSTRUMENT,
} from "../config/ActionTypes";

export const initialState: InstrumentsState = {
  loadingUrl: false,
  url: null,
  urlError: null,
  loadingInstrument: false,
  instrument: null,
  instrumentError: null,
  loadingActiveIssuers: false,
  activeIssuers: null,
  activeIssuersError: null,
};

export function instrumentsReducer(
  state = initialState,
  action: GetUrlTypes | InstrumentTypes | DefaultAction
): InstrumentsState {
  switch (action.type) {
    case GETTING_URL:
      return {
        ...state,
        loadingUrl: true,
        url: null,
        urlError: null,
      };

    case GET_URL_SUCCESS:
      return {
        ...state,
        loadingUrl: false,
        url: action.url,
        urlError: null,
      };

    case GET_URL_ERROR:
      return {
        ...state,
        loadingUrl: false,
        url: null,
        urlError: action.error,
      };

    case GETTING_INSTRUMENT:
      return {
        ...state,
        loadingInstrument: true,
        instrument: null,
        instrumentError: null,
      };

    case GET_INSTRUMENT_SUCCESS:
      return {
        ...state,
        loadingInstrument: false,
        instrument: action.instrument,
        instrumentError: null,
      };

    case GET_INSTRUMENT_ERROR:
      return {
        ...state,
        loadingInstrument: false,
        instrument: null,
        instrumentError: action.error,
      };

    case CLEAR_INSTRUMENT:
      return {
        ...state,
        loadingInstrument: false,
        instrument: null,
        instrumentError: null,
      };

    case GETTING_ISSUERS:
      return {
        ...state,
        loadingActiveIssuers: true,
        activeIssuers: null,
        activeIssuersError: null,
      };

    case GET_ISSUERS_SUCCESS:
      return {
        ...state,
        loadingActiveIssuers: false,
        activeIssuers: action.issuers,
        activeIssuersError: null,
      };

    case GET_ISSUERS_ERROR:
      return {
        ...state,
        loadingActiveIssuers: false,
        activeIssuers: null,
        activeIssuersError: action.error,
      };

    default:
      return state;
  }
}
