import * as React from "react";

function SuccessIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 448"
      xmlSpace="preserve"
      width={72} 
      height={72}
    >
      <path d="M311.9 144.3 190.4 265.6l-54.3-54.3c-7-7-18.4-7-25.4 0s-7 18.4 0 25.4l67 67c3.5 3.5 8.1 5.3 12.7 5.3s9.2-1.7 12.7-5.3l134.1-134.1c7-7 7-18.4 0-25.4s-18.3-7-25.3.1z" />
      <path d="M224 0C100.5 0 0 100.5 0 224s100.5 224 224 224 224-100.5 224-224S347.5 0 224 0zm0 412c-103.7 0-188.1-84.3-188.1-188S120.3 35.9 224 35.9 412 120.3 412 224s-84.3 188-188 188z" />
    </svg>
  );
}

export default SuccessIcon;
