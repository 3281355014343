import { Dispatch } from "redux";
import { cognitoService } from "../../services/cognito.service";
import {
  SIGNINGUP,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  CONFIRMING_USER,
  CONFIRM_USER_SUCCESS,
  CONFIRM_USER_ERROR,
  LOGGINGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  CognitoTypes,
  RESETTING_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SUBMITTING_PASSWORD,
  SUBMIT_PASSWORD_SUCCESS,
  SUBMIT_PASSWORD_ERROR,
  UPDATING_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  CHANGING_PASSWORD,
  CHANGE_PASSWORD_ERROR,
} from "../config/ActionTypes";
import {
  ChangePasswordRequest,
  CognitoLoginRequest,
  CognitoSubmitPasswordRequest,
  CognitoUser,
  CognitoUserResponse,
  ConfirmUserRequest,
  UpdateUserRequest,
} from "../config/types";
import Cookies from "universal-cookie";

//#region Signup

export const cognitoSignUp = (user: CognitoUser) => {
  return (dispatch: Dispatch<CognitoTypes>) => {
    dispatch(signingUp());

    return cognitoService.signUp(user).then(
      (response) => {
        dispatch(signUpSucceeded());
      },
      (error) => {
        dispatch(signUpFailed(error.message));
      }
    );
  };
};

export const signingUp = (): CognitoTypes => ({
  type: SIGNINGUP,
});

export const signUpSucceeded = (): CognitoTypes => ({
  type: SIGNUP_SUCCESS,
});

export const signUpFailed = (error: string): CognitoTypes => ({
  type: SIGNUP_ERROR,
  error,
});

//#endregion

//#region Confirm signup

export const cognitoConfirmSignUp = (request: ConfirmUserRequest) => {
  return (dispatch: Dispatch<CognitoTypes>) => {
    dispatch(confirming());

    return cognitoService.confirmSignUp(request).then(
      (response) => {
        dispatch(confirmSucceeded(request.code));
      },
      (error) => {
        dispatch(confirmFailed(error.message));
      }
    );
  };
};

export const confirming = (): CognitoTypes => ({
  type: CONFIRMING_USER,
});

export const confirmSucceeded = (code: string): CognitoTypes => ({
  type: CONFIRM_USER_SUCCESS,
  code,
});

export const confirmFailed = (error: string): CognitoTypes => ({
  type: CONFIRM_USER_ERROR,
  error,
});

//#endregion

//#region Login

export const cognitoLogin = (request: CognitoLoginRequest) => {
  return (dispatch: Dispatch<CognitoTypes>) => {
    dispatch(loggingIn());

    return cognitoService.logIn(request).then(
      (response) => {
        const cookies = new Cookies();
        cookies.set("token", response.signInUserSession.accessToken.jwtToken, {
          expires: new Date(response.signInUserSession.accessToken.payload["exp"] * 1000),
          path: "/",
        });

        dispatch(loginSucceeded(response.username, response));
      },
      (error) => {
        dispatch(loginFailed(error.message));
      }
    );
  };
};

export const loggingIn = (): CognitoTypes => ({
  type: LOGGINGIN,
});

export const loginSucceeded = (userId: string, user: CognitoUserResponse): CognitoTypes => ({
  type: LOGIN_SUCCESS,
  userId,
  user,
});

export const loginFailed = (error: string): CognitoTypes => ({
  type: LOGIN_ERROR,
  error,
});

//#endregion

//#region Account

export const cognitoAccount = () => {
  return (dispatch: Dispatch<CognitoTypes>) => {
    dispatch(loggingIn());

    return cognitoService.getAccount().then(
      (response) => {
        dispatch(loginSucceeded(response.username, response));
      },
      (error) => {
        dispatch(loginFailed(error.message));
      }
    );
  };
};

//#endregion

//#region Update user

export const updateUser = (updateUserRequest: UpdateUserRequest) => {
  return (dispatch: Dispatch<CognitoTypes>) => {
    dispatch(updatingUser());

    return cognitoService.updateUser(updateUserRequest).then(
      (response) => {
        dispatch(updateUserSuccess(updateUserRequest));
      },
      (error) => {
        dispatch(updateUserFailed(error.message));
      }
    );
  };
};

export const updatingUser = (): CognitoTypes => ({
  type: UPDATING_USER,
});

export const updateUserSuccess = (updateUserRequest: UpdateUserRequest): CognitoTypes => ({
  type: UPDATE_USER_SUCCESS,
  name: updateUserRequest.name,
  email: updateUserRequest.email,
});

export const updateUserFailed = (error: string): CognitoTypes => ({
  type: UPDATE_USER_ERROR,
  error,
});

//#endregion

//#region Reset password

export const resetPassword = (username: string) => {
  return (dispatch: Dispatch<CognitoTypes>) => {
    dispatch(resettingPassword());

    return cognitoService.resetPassword(username).then(
      (response) => {
        dispatch(resetPasswordSuccess());
      },
      (error) => {
        dispatch(resetPasswordFailed(error.message));
      }
    );
  };
};

export const resettingPassword = (): CognitoTypes => ({
  type: RESETTING_PASSWORD,
});

export const resetPasswordSuccess = (): CognitoTypes => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailed = (error: string): CognitoTypes => ({
  type: RESET_PASSWORD_ERROR,
  error,
});

//#endregion

//#region Submit reset password

export const submitResetPassword = (request: CognitoSubmitPasswordRequest) => {
  return (dispatch: Dispatch<CognitoTypes>) => {
    dispatch(submittingPassword());

    return cognitoService.submitResetPassword(request).then(
      (response) => {
        dispatch(submitPasswordSuccess());
      },
      (error) => {
        dispatch(submitPasswordFailed(error.message));
      }
    );
  };
};

export const submittingPassword = (): CognitoTypes => ({
  type: SUBMITTING_PASSWORD,
});

export const submitPasswordSuccess = (): CognitoTypes => ({
  type: SUBMIT_PASSWORD_SUCCESS,
});

export const submitPasswordFailed = (error: string): CognitoTypes => ({
  type: SUBMIT_PASSWORD_ERROR,
  error,
});

//#endregion

//#region Change password

export const changePassword = (changePasswordRequest: ChangePasswordRequest) => {
  return (dispatch: Dispatch<CognitoTypes>) => {
    dispatch(changingPassword());

    return cognitoService.changePassword(changePasswordRequest).then(
      (response) => {
        dispatch(changePasswordSuccess());
      },
      (error) => {
        dispatch(changePasswordFailed(error.message));
      }
    );
  };
};

export const changingPassword = (): CognitoTypes => ({
  type: CHANGING_PASSWORD,
});

export const changePasswordSuccess = (): CognitoTypes => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFailed = (error: string): CognitoTypes => ({
  type: CHANGE_PASSWORD_ERROR,
  error,
});

//#endregion
