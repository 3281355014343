import React, { useEffect, useState } from "react";
import { urlVarsHelper } from "../../../helpers/urlVarsHelper";
import { Loading } from "../Loading";
import { RootState } from "../../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../store/config/ActionTypes";
import { pageRedirect } from "../../../store/action_creators/purchases.actions";
import { ConnectedProps, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { PaymentStatusEnum } from "../../../store/config/enums";

const mapStateToProps = (state: RootState) => ({
  purchases: state.purchases,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  pageRedirect: (sessionId: string) => dispatch(pageRedirect(sessionId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
//type PropsType = PropsFromRedux & RouteComponentProps<any>;

function Redirect({ purchases, pageRedirect }: PropsFromRedux) {
  const isIframe = window.location !== window.parent.location;
  const [hasError, setHasError] = useState(false);

  const history = useHistory();

  useEffect(() => {
    let urlVars = urlVarsHelper.getUrlVars();
    if (urlVars.errorcode) {
      setHasError(true);
    } else if (
      urlVars.sessionId &&
      !purchases.loadingPurchaseData &&
      purchases.purchaseData === null &&
      purchases.loadPurchaseDataError === null &&
      !isIframe
    ) {
      pageRedirect(urlVars.sessionId);
    } else if (purchases.purchaseData !== null) {
      // navigate(`/confirm/?sessionId=${purchases.sessionId}`);
    }

    if (purchases.loadPurchaseDataError && !isIframe) {
      history.push("/error?errorCode=99");
    }
  }, [
    history,
    setHasError,
    purchases.loadingPurchaseData,
    purchases.purchaseData,
    purchases.loadPurchaseDataError,
    purchases.sessionId,
    pageRedirect,
    isIframe,
  ]);

  useEffect(() => {
    if (
      purchases.purchaseSuccess &&
      purchases.transactionCallbackResponse?.status !== PaymentStatusEnum.PENDING
    ) {
      history.push(`/result?isCard=${!purchases.transactionCallbackResponse?.notACard}`)
    }

    if (purchases.purchaseError !== null) {
      history.push(
        purchases.transactionCallbackResponse?.notACard
          ? '/error?errorCode=8'
          : '/error?errorCode=3'
      )
    }
  }, [
    history,
    purchases.purchaseSuccess,
    purchases.purchaseError,
    purchases.transactionCallbackResponse
  ])

  if (isIframe) {
    const message = hasError ? "redirectError" : "redirectSuccess";
    window.parent.postMessage(message, `${window.location.origin}/redirect`);
    return <Loading message="Procesando el pago" dark />;
  } else {
    if (hasError) {
      history.push("/error/?errorCode=4");
    }
    return <Loading dark={false} />;
  }
}

export default connector(Redirect);