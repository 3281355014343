import * as signalR from "@microsoft/signalr";
import { Middleware } from "redux";
import { RootState, TransactionCallbackResponse } from "../config/types";
import * as ActionTypes from "../config/ActionTypes";
import { purchaseFailed, purchaseSuccess, registerCallbackFailed } from "../action_creators/purchases.actions";
import { urlHelper } from "../../helpers/urlHelper";

const startSignalRConnection = async (connection: signalR.HubConnection, sessionId: string, message: string) => {
  return await connection
    .start()
    .then(() => {
      console.log("signalR done");
      connection.send(message, sessionId); //Todo: are these the props we want/need to send?
      return connection;
    })
    .catch((error) => {
      console.log("signalR error: " + error);
    });
};

const signalRMiddleware: Middleware<
  {}, // legacy type parameter added to satisfy interface signature
  RootState
> = (store) => (next) => (action) => {
  const connectionHub = urlHelper.getHubUrl();
  const protocol = new signalR.JsonHubProtocol();
  //const transport = signalR.HttpTransportType.WebSockets;

  const options = {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets
  };

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(connectionHub, options)
    .configureLogging(signalR.LogLevel.Critical)
    .withHubProtocol(protocol)
    .build();

  //connection.onclose(() => setTimeout(startSignalRConnection(connection), 5000));

  connection.on("RegisterCallback", (success: boolean) => {
    console.log("RegisterCallback received: " + success);
    if (!success) {
      next(registerCallbackFailed(success));
    }
  });

  connection.on("TransactionCallback", (transactionCallbackResponse: TransactionCallbackResponse) => {
    console.log("TransactionCallback received");
    connection.send("PlexoDisconnect");
    connection.stop();

    if (transactionCallbackResponse.isSuccess) {
      next(purchaseSuccess(transactionCallbackResponse));
    } else {
      next(purchaseFailed(transactionCallbackResponse.message));
    }
  });

  if (action.type === ActionTypes.GET_URL_SUCCESS) {
    startSignalRConnection(connection, action.sessionId, "PlexoInit");
  }

  if (action.type === ActionTypes.PAGE_REDIRECTED) {
    // got sessionId from query string
    console.log("PAGE REDIRECTED, starting signalR connection");
    startSignalRConnection(connection, action.sessionId, "RedirectStatus");
  }

  return next(action);
};

export default signalRMiddleware;
