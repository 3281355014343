import { get, post } from "./base.service";
import { Session, Instrument, Issuer, PurchaseRequest } from "../store/config/types";
import { endpoints } from "./endpoints";
import Cookies from "universal-cookie";

export const instrumentsService = {
  getPlexoUrl,
  getInstrument,
  getIssuers,
};

export interface PlexoUrl {
  url: string;
}

async function getPlexoUrl({ sessionId, fingerprint }: PurchaseRequest): Promise<string> {
  const cookies = new Cookies();
  const token = cookies.get("token");
  const hasToken = token !== undefined;
  const body = {
    RedirectUrl: `${window.location.origin}/redirect?sessionId=${sessionId}`,
    Fingerprint: fingerprint,
  };

  let headers = new Headers({ "Content-Type": "application/json" });
  if (hasToken) {
    headers.append("accessToken", token);
  }

  return await post<string>(`${endpoints["getPlexoUrl"]}/${sessionId}`, body, {
      method: "post",
      headers,
      body: JSON.stringify(body),
    })
    .then((response) => {
      return response.stringBody!;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function getInstrument({ sessionId }: Session): Promise<Instrument | undefined> {
  return await get<Instrument>(`${endpoints["getInstrument"]}/${sessionId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
async function getIssuers({ sessionId }: Session): Promise<Issuer[] | undefined> {
  return await get<Issuer[]>(`${endpoints["getIssuers"]}/${sessionId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
