import React from "react";
import { Issuer } from "../../store/config/types";

interface PartnersFooterProps {
  issuers: Issuer[];
}

function PartnersFooter({ issuers }: PartnersFooterProps) {

  return (
    <div>
        {issuers && issuers.map((i) => <img key={i.issuerName} src={i.imageUrl} alt={`Logo de ${i.issuerName}`} />)}
    </div>
  );
}

export default PartnersFooter;
