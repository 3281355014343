import React from "react";

function Download() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 32 32">
      <g>
        <path d="M31,22.8v6.6c0,0.1,0,0.3,0,0.4c-0.1,0.7-0.7,1.1-1.4,1.1L2.4,31c-0.7,0-1.3-0.6-1.4-1.3c0-0.1,0-0.2,0-0.3v-6.6
        c0-0.8,0.6-1.4,1.4-1.4c0.4,0,0.7,0.2,1,0.4s0.4,0.6,0.4,1v5.3h24.3v-5.3c0-0.8,0.6-1.4,1.4-1.4c0.4,0,0.7,0.2,1,0.4
        C30.8,22.1,31,22.4,31,22.8L31,22.8z" fill="#fff" />
        <path d="M24.2,16.2l-7,7c0,0,0,0,0,0c-0.3,0.3-0.6,0.4-0.9,0.5c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0l-0.2,0c0,0-0.1,0-0.1,0
        c-0.3,0-0.7-0.2-0.9-0.5c0,0,0,0,0,0l-7-7c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.4,0.2-0.9,0.5-1.2c0.6-0.6,1.7-0.6,2.3,0l4.2,4.2l0-15.3
        C14.4,1.8,15.1,1,16,1c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2V18l4.2-4.2c0.6-0.6,1.7-0.6,2.3,0
        C24.8,14.5,24.8,15.5,24.2,16.2L24.2,16.2z" fill="#fff" />
      </g>
    </svg>
  );
}

export default Download;
