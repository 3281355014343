import { ErrorCodes, CheckMerchantErrorCodes, CognitoErrorCodes } from "../store/config/enums";

export const errorHelper = {
  getMerchantErrorMessage,
  getLinkErrorMessage,
  getCognitoErrorMessage,
};

function getMerchantErrorMessage(code: number): string {
  switch (code) {
    case CheckMerchantErrorCodes.INVALID_MERCHANT:
      return "El comercio al que quieres acceder no es válido";

    case CheckMerchantErrorCodes.DISABLED_MERCHANT:
      return "El comercio al que quieres acceder está desactivado";

    case CheckMerchantErrorCodes.CHECK_MERCHANT_FAILED:
      return "No hemos podido verificar el comercio al que quieres acceder";

    case CheckMerchantErrorCodes.INVALID_MERCHANT_PLEXO_ID:
      return "El comercio al que quieres acceder está mal configurado";

    case CheckMerchantErrorCodes.INVALID_ERROR_CODE:
      return "El código de error no es válido";

    case CheckMerchantErrorCodes.MERCHANT_ID_IS_NOT_A_GUID:
      return "El formato del identificador del comercio no es correcto";

    default:
      return "Ocurrió un error inesperado";
  }
}

function getLinkErrorMessage(code: number): string {
  switch (code) {
    case ErrorCodes.INVALID_LINK:
      return "El link de pago no es válido";

    case ErrorCodes.EXPIRED_LINK:
    case ErrorCodes.INVALID_MERCHANT_PLEXO_ID:
      return "El link de pago está expirado";

    case ErrorCodes.ALREADY_USED_LINK:
      return "El link de pago ya fue usado anteriormente";

    case ErrorCodes.CHECK_LINK_FAILED:
      return "Ocurrió un error al checkear el link de pago";

    case ErrorCodes.INVALID_STATUS:
      return "El link de pago tiene un estado que no es válido";

    case ErrorCodes.NO_CONTENT:
      return "No hay contenido";

    case ErrorCodes.DATABASE_ERROR:
      return "Ocurrió un error con la base de datos";

    case ErrorCodes.UNKNOWN_ERROR:
      return "Ocurrió un error desconocido";

    case ErrorCodes.ARGUMENT_ERROR:
      return "Alguno de los argumentos enviados es incorrecto";

    case ErrorCodes.INVALID_SESSION:
      return "La sesión provista no es válida";

    case ErrorCodes.INSTRUMENT_NOT_FOUND:
      return "No encontramos el instrumento de pago";

    case ErrorCodes.PLEXO_PAYMENT_ERROR:
      return "Ocurrió un error al realizar el pago en Plexo";

    case ErrorCodes.PLEXO_AUTHORIZATION_ERROR:
      return "Ocurrió un error en la autorización de Plexo";

    default:
      return "Ocurrió un error inesperado";
  }
}

function getCognitoErrorMessage(code: string): string {
  switch (code) {
    case CognitoErrorCodes.INVALID_PARAMETERS:
      return "Uno o más de los campos ingresados no son válidos.";
    case CognitoErrorCodes.USER_EXISTS:
      return "Ya existe un usuario con el email ingresado.";
    case CognitoErrorCodes.NOT_AUTHORIZED:
      return "Las credenciales ingresadas no son correctas.";
    case CognitoErrorCodes.EXPIRED_CODE:
      return "El código provisto está expirado";
    default:
      return "Ocurrió un error inesperado al intentar registrar el nuevo usuario.";
  }
}
