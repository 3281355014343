import React, { useEffect, useState } from "react";
import { Loading } from "../../components/common/Loading";
import { urlVarsHelper } from "../../helpers/urlVarsHelper";
import { connect, ConnectedProps } from "react-redux";
import { RootState, Session } from "../../store/config/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../store/config/ActionTypes";
import { fetchPurchaseData } from "../../store/action_creators/purchases.actions";
import { useHistory } from "react-router-dom";
import loadAnimation from "../../assets/loadAnimation.json";
import { useLottie } from "lottie-react";
import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      minHeight: "100vh",
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  })
);

const mapStateToProps = (state: RootState) => ({
  purchases: state.purchases,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchPurchaseData: ({ sessionId }: Session) => dispatch(fetchPurchaseData({ sessionId })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function LoadingScreen({ purchases, fetchPurchaseData }: PropsFromRedux) {
  const history = useHistory();
  const classes = useStyles();

  const [plexoLoading, setPlexoLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    let urlVars = urlVarsHelper.getUrlVars();

    if (urlVars.plexoLoading) {
      setPlexoLoading(true);
      return;
    }

    setMounted(true);

    if (urlVars.sessionId) {
      if (
        !purchases.loadingPurchaseData &&
        purchases.purchaseData === null &&
        purchases.loadPurchaseDataError === null
      ) {
        fetchPurchaseData({ sessionId: urlVars.sessionId });
      }
    } else if (urlVars.errorCode) {
      history.push(`/error?errorCode=plexo_${urlVars.errorCode}`);
    } else {
      history.push("/error?errorCode=6");
    }

    if (purchases.purchaseData !== null) {
      history.push("/details/?sessionId=" + urlVars.sessionId);
    }

    if (purchases.loadPurchaseDataError) {
      history.push("/error?errorCode=99");
    }
  }, [
    purchases.loadingPurchaseData,
    purchases.purchaseData,
    purchases.loadPurchaseDataError,
    fetchPurchaseData,
    history
  ]);

  const options = {
    animationData: loadAnimation,
    loop: 2,
    width:150,
    height:150
  };
  const { View } = useLottie(options);

  if (plexoLoading) {
    return (
      <div className="loading-with-text">
        <>{View}</>
      </div>
    );
  }

  if (mounted) {
    return (
      <div className={classes.page}>
        <Loading dark={false} />
      </div>
    );
  }

  return null;
}

export default connector(LoadingScreen);
