import { errorHelper } from "../helpers/errorHelper";
import { ErrorCodes } from "../store/config/enums";

export interface HttpResponse<T> extends Response {
  parsedBody?: T;
  stringBody?: string;
}

async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(request);

  await response.text().then((body) => {
    try {
      response.parsedBody = JSON.parse(body);
    } catch (ex) {
      response.stringBody = body;
    }
  });

  if (!response.ok) {
    if (response.parsedBody !== undefined 
        && response.parsedBody !== null
        && typeof response.parsedBody === 'object'
        && "errorCode" in response.parsedBody) {
      let code = ((response.parsedBody as unknown) as { errorCode: string })["errorCode"];
      const message = ((response.parsedBody as unknown) as { message: string })["message"];
      let error = message
      if (Number(code) < 1200) {
        if (message === 'The branch status is not active') {
          error = errorHelper.getLinkErrorMessage(ErrorCodes.EXPIRED_LINK)
        } else {
          error = errorHelper.getLinkErrorMessage(Number(code))
        }
      }
      throw new Error(error);
    }

    throw new Error(response.statusText);
  }

  return response;
}

export async function get<T>(
  path: string,
  args: RequestInit = { method: "get", headers: new Headers({ "Content-Type": "application/json" }) }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function post<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: "post",
    headers: new Headers({ "Content-Type": "application/json" }),
    body: JSON.stringify(body),
  }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function put<T>(
  path: string,
  body: any,
  args: RequestInit = { method: "put", body: JSON.stringify(body) }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}
