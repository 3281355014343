import { TransactionsState } from "../config/types";
import { ADD_TRANSACTIONS, LOADING_TRANSACTIONS, TransactionsTypes, TRANSACTIONS_ERROR } from "../config/ActionTypes";

// const data = [
//   { id: "1", ending: "1234", mark: "Visa", date: "21/11/2202", currency: "$", amount: 1304 },
//   { id: "2", ending: "1234", mark: "Visa", date: "21/11/2202", currency: "USD", amount: 1304 },
//   { id: "3", ending: "1234", mark: "Visa", date: "21/11/2102", currency: "$", amount: 1304 },
//   { id: "4", ending: "1234", mark: "Master", date: "21/11/2202", currency: "$", amount: 1304 },
//   { id: "5", ending: "4200", mark: "Visa", date: "21/11/2202", currency: "$", amount: 1304 },
//   { id: "6", ending: "1234", mark: "Abitar", date: "21/11/2202", currency: "$", amount: 1304 },
// ];

export const initialState: TransactionsState = {
  loadingTransactions: false,
  transactions: null,
  errorMessage: null,
};

export function transactionsReducer(state = initialState, action: TransactionsTypes): TransactionsState {
  switch (action.type) {
    case LOADING_TRANSACTIONS:
      return {
        ...state,
        loadingTransactions: true,
        transactions: null,
        errorMessage: null,
      };

    case ADD_TRANSACTIONS:
      return {
        ...state,
        loadingTransactions: false,
        transactions: action.transactions,
        errorMessage: null,
      };

    case TRANSACTIONS_ERROR:
      return {
        ...state,
        loadingTransactions: false,
        transactions: null,
        errorMessage: action.error,
      };

    default:
      return state;
  }
}
