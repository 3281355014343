import React from "react";

interface LogoProps {
  handColor?: string;
  textColor?: string;
}

const Logo = ({ handColor, textColor }: LogoProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="132.782"
      height="35.391"
      viewBox="0 0 132.782 35.391"
    >
      <g
        id="Group_38"
        data-name="Group 38"
        transform="translate(-109.134 -214.529)"
      >
        <path
          id="Path_1"
          data-name="Path 1"
          d="M126.37,267.487v18.179h-4.785v-6.848h-7.665v6.848h-4.785V267.487h4.785v6.746h7.665v-6.746Z"
          transform="translate(0 -41.682)"
          fill={textColor ? textColor : "#fff"}
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M214.107,286.374v13.913h-4.134l-.342-1.552a4.462,4.462,0,0,1-3.877,1.906,6.228,6.228,0,0,1-4.641-2.005,7.279,7.279,0,0,1-1.939-5.284,7.154,7.154,0,0,1,2.038-5.195,6.444,6.444,0,0,1,4.763-2.094,4.368,4.368,0,0,1,3.7,1.914l.3-1.6Zm-5.292,9.151a3.145,3.145,0,0,0,.751-2.172,3.181,3.181,0,0,0-.751-2.2,2.8,2.8,0,0,0-2.155-.781,3.443,3.443,0,0,0-2.156.684,2.709,2.709,0,0,0-.893,2.293,2.679,2.679,0,0,0,.893,2.269,3.439,3.439,0,0,0,2.156.683A2.8,2.8,0,0,0,208.815,295.525Z"
          transform="translate(-70.869 -56.303)"
          fill={textColor ? textColor : "#fff"}
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M296.377,300.123h-4.652v-7.511a1.99,1.99,0,0,0-.576-1.584,2.7,2.7,0,0,0-1.795-.5,3.354,3.354,0,0,0-3.1,1.751v7.843H281.6V286.21h4.294l.359,1.648a5.978,5.978,0,0,1,4.741-2,5.328,5.328,0,0,1,3.943,1.44,5.149,5.149,0,0,1,1.44,3.811Z"
          transform="translate(-135.744 -56.139)"
          fill={textColor ? textColor : "#fff"}
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M451.112,287.521s-3.9,10.215-5.353,13.913c-1.75,4.464-4.265,5.095-8.086,5.095l-.443-4.453c2.5,0,3.16-.2,3.5-.629l-5.737-13.926h4.9l3.367,9.371,3.323-9.371Z"
          transform="translate(-256.477 -57.45)"
          fill={textColor ? textColor : "#fff"}
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M373.426,267.487v18.179h-4.134l-.341-1.552a4.463,4.463,0,0,1-3.877,1.906,6.228,6.228,0,0,1-4.641-2.005,7.279,7.279,0,0,1-1.938-5.284,7.154,7.154,0,0,1,2.038-5.195,6.444,6.444,0,0,1,4.763-2.094,4.92,4.92,0,0,1,4,1.914v-5.87ZM368.134,280.9a3.144,3.144,0,0,0,.751-2.172,3.18,3.18,0,0,0-.751-2.2,2.8,2.8,0,0,0-2.155-.781,3.443,3.443,0,0,0-2.155.684,2.708,2.708,0,0,0-.893,2.293,2.679,2.679,0,0,0,.893,2.269,3.439,3.439,0,0,0,2.155.683A2.8,2.8,0,0,0,368.134,280.9Z"
          transform="translate(-196.266 -41.682)"
          fill={textColor ? textColor : "#fff"}
        />
        <g id="Group_1" data-name="Group 1" transform="translate(205.958 214.529)">
          <path
            id="Path_6"
            data-name="Path 6"
            d="M567.832,223.886l5.229-5.23a2.113,2.113,0,0,1,2.988,2.989l-4.095,4.093a1.212,1.212,0,0,0,1.714,1.715l12.3-12.3a2.113,2.113,0,0,1,2.989,2.988l-8.773,8.773a1.212,1.212,0,0,0,1.715,1.714l11.8-11.8a2.113,2.113,0,0,1,2.989,2.988l-11.8,11.8a1.212,1.212,0,0,0,1.713,1.715l9.622-9.623a2.113,2.113,0,0,1,2.989,2.988l-10.341,10.341a1.212,1.212,0,0,0,1.714,1.714l4.422-4.422A2.113,2.113,0,0,1,598,237.313l-8.369,8.37c-6.769,6.768-15.358,4.65-20.9-.9s-6.979-14.819-.895-20.9"
            transform="translate(-563.875 -214.529)"
            fill={handColor ? handColor : "#fff"}
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
