import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { FeedbackBox } from "../../components/common/FeedbackBox";
import { urlVarsHelper } from "../../helpers/urlVarsHelper";
import { RootState } from "../../store/config/types";
import { connect, ConnectedProps } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      alignItems: "flex-start",
      minHeight: "100vh",
      height: "100%",
      justifyContent: "center",
      background: '#f0f0f0',
      width: '100%',
    },
  })
);

const mapStateToProps = (state: RootState) => ({
  purchases: state.purchases,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function ErrorScreen({ purchases }: PropsFromRedux) {
  const [errorCode, setErrorCode] = useState<string>("99");

  const classes = useStyles();

  useEffect(() => {
    let urlVars = urlVarsHelper.getUrlVars();

    if (urlVars.errorCode) {
      if (errorCode !== urlVars.errorCode) {
        setErrorCode(urlVars.errorCode);
      }
    } else {
      setErrorCode("5");
    }
  }, [errorCode, setErrorCode]);

  return (
    <div className={classes.mainContainer}>
      {errorCode !== null ? (
        <FeedbackBox
          errorCode={errorCode}
          errorMessage={purchases.loadPurchaseDataError !== null ? purchases.loadPurchaseDataError : ""}
          purchaseData={purchases?.purchaseData}
          plexoError={purchases.purchaseError}
          showActions={true}
        />
      ) : null}
    </div>
  );
}

export default connector(ErrorScreen);
