import React from 'react';
import { LinkIcon } from '../Icons/LinkIcon';
import LinkIconExport from '../Icons/LinkIcon/export.png';
import { ErrorIcon } from '../Icons/ErrorIcon';
import ErrorIconExport from '../Icons/ErrorIcon/export.png';
import { SuccessIcon } from '../Icons/SuccessIcon';
import SuccessIconExport from '../Icons/SuccessIcon/export.png';
import { PendingIcon } from '../Icons/PendingIcon';
import PendingIconExport from '../Icons/PendingIcon/export.png';

export const getContent = (errorCode, companyName, companyUrl) => {
  const hasCompanyUrl = companyUrl !== undefined && companyUrl !== null && companyUrl !== '';
  switch (errorCode) {
    case '0':
      return {
        icon: <SuccessIcon />,
        iconExportPath: SuccessIconExport,
        success: true,
        errorTitle: '¡Pago finalizado!',
        errorSubTitle: 'Pago realizado.',
        companyLogo: false,
        contentText: `¡Gracias por utilizar Handy! Su pago se procesó correctamente y se notificará al proveedor.`,
        footer: false,
        footerTxt: '',
        button: hasCompanyUrl,
        buttonUrl: companyUrl,
        buttonTxt: `Volver a ${companyName}`,
      };
    case '1':
      return {
        icon: <ErrorIcon />,
        iconExportPath: ErrorIconExport,
        success: false,
        errorTitle: '¡Ups!',
        errorSubTitle: 'El vínculo de compra ya no está disponible.',
        companyLogo: companyName,
        contentText: `Por motivos de seguridad, los vínculos de compra tienen 15 minutos de vigencia. 
      Comuníquese con ${companyName ? companyName : 'su proveedor'} para que se lo vuelva a generar.`,
        footer: true,
        footerTxt: 'Disculpas por las molestias ocasionadas',
        button: false,
        buttonTxt: '',
      };
    case '2':
      return {
        icon: <LinkIcon />,
        iconExportPath: LinkIconExport,
        success: false,
        errorTitle: '¡Ups!',
        errorSubTitle: 'El vínculo de compra ya fue utilizado.',
        companyLogo: companyName,
        contentText: `Los vínculos de compra pueden ser utilizados una única vez. 
        Comuníquese con ${companyName ? companyName : 'su proveedor'} si quiere volver a realizar la misma compra.`,
        footer: true,
        footerTxt: 'Disculpas por las molestias ocasionadas',
        button: false,
        buttonTxt: '',
      };
    case '3':
      return {
        icon: <ErrorIcon />,
        iconExportPath: ErrorIconExport,
        success: false,
        errorTitle: 'El proceso de pago no pudo ser realizado.',
        errorSubTitle: '',
        companyLogo: false,
        contentText: `Algo salió mal durante el proceso de pago y no pudimos concretarlo. 
        No se ha debitado el monto correspondiente de su medio de pago, 
        por lo que puede volver al sitio de ${companyName} e intentarlo nuevamente.`,
        footer: false,
        footerTxt: '',
        button: true,
        buttonUrl: hasCompanyUrl,
        buttonTxt: `Volver a ${companyName}`,
      };
    case '4':
      return {
        icon: <ErrorIcon />,
        iconExportPath: ErrorIconExport,
        success: false,
        errorTitle: '¡Ups!',
        errorSubTitle: 'Ocurrió un error al cargar su medio de pago.',
        companyLogo: false,
        contentText: `Vuelve a intentarlo para seguir con el proceso de pago.`,
        footer: false,
        footerTxt: '',
        button: true,
        buttonTxt: `Volver a ingresar el medio de pago`,
      };
    case '5':
      return {
        icon: <LinkIcon />,
        success: false,
        errorTitle: '¡Ups!',
        errorSubTitle: 'El vínculo de compra se ha vencido.',
        companyLogo: companyName,
        contentText: `Los vínculos de compra se vencen después de un tiempo. 
        Comuníquese con ${companyName ? companyName : 'su proveedor'} si quiere volver a realizar la misma compra.`,
        footer: true,
        footerTxt: 'Disculpas por las molestias ocasionadas',
        button: false,
        buttonTxt: '',
      };
    case '6':
      return {
        icon: <LinkIcon />,
        iconExportPath: LinkIconExport,
        success: false,
        errorTitle: 'Url mal formada',
        errorSubTitle: 'No pudimos identificar el pago que desea efectuar.',
        companyLogo: false,
        contentText: `La Url de Handy que ingresas en el navegador debe tener el parámetro sessionId, para que podamos identificar el pago al que desea acceder.`,
        footer: false,
        footerTxt: '',
        button: false,
        buttonTxt: '',
      };
    case '7':
      return {
        icon: <PendingIcon />,
        iconExportPath: PendingIconExport,
        success: true,
        errorTitle: 'Pago pendiente',
        errorSubTitle: 'Solicitud generada.',
        companyLogo: false,
        contentText: `¡Gracias por utilizar Handy! Su solicitud se procesó correctamente y podrá realizar el pago en la red de cobranza elegida.`,
        footer: false,
        footerTxt: '',
        button: hasCompanyUrl,
        buttonUrl: companyUrl,
        buttonTxt: `Volver a ${companyName}`,
      };
    case '8':
      return {
        icon: <ErrorIcon />,
        iconExportPath: ErrorIconExport,
        success: false,
        errorTitle: 'Ha sucedido un error',
        errorSubTitle: 'La solicitud de pago no pudo ser procesada.',
        companyLogo: false,
        contentText: `Algo salió mal durante el proceso de solicitud y no pudimos concretarla.`,
        footer: false,
        footerTxt: '',
        button: hasCompanyUrl,
        buttonUrl: companyUrl,
        buttonTxt: `Volver a ${companyName}`,
      };
    case '9':
      return {
        icon: <SuccessIcon />,
        iconExportPath: SuccessIconExport,
        success: true,
        errorTitle: 'Usuario registrado',
        errorSubTitle: '',
        companyLogo: false,
        contentText: `¡Gracias por registrarse con Handy! Revise su casilla de correo para verificar su usuario y comenzar a utilizar la plataforma.`,
        footer: false,
        footerTxt: '',
        button: false,
        buttonUrl: companyUrl,
        buttonTxt: '',
      };
    case '10':
      return {
        icon: <ErrorIcon />,
        iconExportPath: ErrorIconExport,
        success: false,
        errorTitle: 'Ha sucedido un error',
        errorSubTitle: 'No se pudo verificar el usuario.',
        companyLogo: false,
        contentText: `Algo salió mal y no pudimos verificar su correo para verificar su usuario.`,
        footer: false,
        footerTxt: '',
        button: false,
        buttonUrl: '',
        buttonTxt: '',
      };
    case '11':
      return {
        icon: <SuccessIcon />,
        iconExportPath: SuccessIconExport,
        success: true,
        errorTitle: 'Correo verificado',
        errorSubTitle: '',
        companyLogo: false,
        contentText: `¡Gracias por registrarse con Handy! Hemos verificado su correo y ya puede comenzar a usar la plataforma. Para continuar con el proceso de pago, vuelve a clickear el link de pago e ingresa tu usuario y contraseña.`,
        footer: false,
        footerTxt: '',
        button: false,
        buttonUrl: companyUrl,
        buttonTxt: '',
      };
    case '12':
      return {
        icon: <PendingIcon />,
        iconExportPath: PendingIconExport,
        success: true,
        errorTitle: 'Pago pendiente',
        errorSubTitle: 'Solicitud generada.',
        companyLogo: false,
        contentText: `¡Listo! ¡Gracias por utilizar Handy! Tu solicitud se procesó correctamente. Te confirmaremos cuando tu compra quede aprobada.`,
        footer: false,
        footerTxt: '',
        button: hasCompanyUrl,
        buttonUrl: companyUrl,
        buttonTxt: `Volver a ${companyName}`,
      };
    case '13':
      return {
        icon: <PendingIcon />,
        iconExportPath: PendingIconExport,
        success: true,
        errorTitle: 'Pago pendiente',
        errorSubTitle: 'Solicitud generada.',
        companyLogo: false,
        contentText: `¡Gracias por utilizar Handy! Su solicitud se encuentra pendiente para ser procesada y aprobada  por el banco.`,
        footer: false,
        footerTxt: '',
        button: hasCompanyUrl,
        buttonUrl: companyUrl,
        buttonTxt: `Volver a ${companyName}`,
      };
    default:
      return {
        icon: <ErrorIcon />,
        iconExportPath: ErrorIconExport,
        success: false,
        errorTitle: 'Ha habido un error',
        errorSubTitle: 'La página a la cual intentas acceder no existe.',
        companyLogo: false,
        contentText: '',
        footer: true,
        footerTxt: 'Disculpas por las molestias ocasionadas',
        button: false,
        buttonTxt: '',
      };
  }
};
