import React from "react";
import "./styles/app.scss";
import Main from "./components/Main";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { theme } from "./theme";
import { Provider } from "react-redux";
import { store } from "./store/config/configureStore";
import { Amplify } from "aws-amplify";
import { BrowserRouter } from "react-router-dom";

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    //identityPoolId: process.env.REACT_APP_COGNITO_POOL_ID,

    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION,

    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_ID,
  },
});


function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <CssBaseline />
          <Main />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
