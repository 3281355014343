import { Currency } from "../store/config/enums";

export interface CurrencyObj {
  symbol: string;
  name: string;
}

export const currencyHelper = {
    getCurrencyData,
}

function getCurrencyData(code: number): CurrencyObj {
  switch (code) {
    case Currency.PESO:
      return { symbol: "$", name: "Pesos" };
    case Currency.DOLAR:
      return { symbol: "US$", name: "Dólares" };
    default:
      return { symbol: "$", name: "Pesos" };
  }
}
