import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/config/types";
import { Button, CircularProgress, Paper, TextField } from "@mui/material";
import QrCodeImage from "../../assets/qr.svg";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../store/config/ActionTypes";
import { generatePaymentLink } from "../../store/action_creators/purchases.actions";
import { urlVarsHelper } from '../../helpers/urlVarsHelper'
import { useLocation } from "react-router-dom";

const mapStateToProps = (state: RootState) => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  generatePaymentLink:
    (amount: number, secret: string, callback: any) => dispatch(generatePaymentLink(amount, secret, callback)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function SelfCheckout({ generatePaymentLink }: PropsFromRedux) {
  const [productPrice, setProductPrice] = useState('')
  const [loading, setLoading] = useState(false)
  const [inputFontSize, setInputFontSize] = useState(65)
  const [error, setError] = useState('')
  const location = useLocation();
  const secret = urlVarsHelper.getUrlVars(location.search.replace('?', ''))
  const langEs = navigator.language.includes('es');

  const canPay = parseFloat(productPrice) > 0

  const handleProductPriceOnChange = (e: any) => {
    if (e.target.value.length === 0 || parseFloat(e.target.value) >= 0) {
      setProductPrice(e.target.value);

      if (parseFloat(e.target.value) < 1000) {
        setInputFontSize(65)
      } else if (parseFloat(e.target.value) >= 1000 && parseFloat(e.target.value) < 100000) {
        setInputFontSize(50)
      } else if (parseFloat(e.target.value) >= 100000 && parseFloat(e.target.value) < 1000000) {
        setInputFontSize(40)
      } else if (parseFloat(e.target.value) >= 500000) {
        setInputFontSize(35)
      }
    }
  };

  const handleProductPriceOnBlur = () => {
    setProductPrice(parseFloat(productPrice).toFixed(2))
  };

  const handleGeneratePaymentLink = async () => {
    setLoading(true);

    generatePaymentLink(parseFloat(productPrice), secret.merchant, (paymentLink: any, error: string) => {
      setError('')
      if (paymentLink && paymentLink.url) {
        window.location.replace(paymentLink.url);
      } else if (error) {
        setError(error)
      }
      setLoading(false);
    });
  }

  return (
    <div className="self-checkout-wrapper">
      <Paper className="self-checkout-container">
        {error ? (<React.Fragment><div className="self-checkout-box self-checkout-box-danger">
          <div>
            {error}
          </div>
        </div><div className="self-checkout-box-separator" /></React.Fragment>) : null}
        <div className="self-checkout-box self-checkout-box-info">
          <img src={QrCodeImage} className="self-checkout-box-image" alt="qr" />
          <div>
            Para continuar la compra<br />ingresa el importe a pagar, por favor
          </div>
        </div>
        <div className="self-checkout-amount-container">
          <div className="self-checkout-amount-currency">
            UYU
          </div>
          <TextField
            id="standard-basic" variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              style: {
                fontSize: `${inputFontSize}px`
              }
            }}
            className="self-checkout-amount-input"
            type="number"
            placeholder={langEs ? "0,00" : "0.00"}
            onChange={handleProductPriceOnChange}
            onBlur={handleProductPriceOnBlur}
            value={productPrice}
          />
        </div>
        <Button
          className="self-checkout-submit"
          color="primary"
          variant="contained"
          type="submit"
          disabled={!canPay || loading}
          onClick={handleGeneratePaymentLink}
          disableElevation
        >
          {loading ? <CircularProgress className="self-checkout-submit-loading" color="inherit" /> : 'Pagar'}
        </Button>
      </Paper>
    </div>
  );
}

export default connector(SelfCheckout);
