import React from "react";
import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Logo } from "../../common/Logo";
import { colors } from "../../../theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainLoadingContainer: {
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 14,
      backgroundColor: colors.primaryContrast,
      color: colors.darkTxtColor,
    },
    dark: {
      backgroundColor: colors.primary,
      color: colors.coloredTxtColor,
    },
  })
);

interface LoadingProps {
  dark: boolean;
  message?: string;
}

function Loading({ dark = false, message }: LoadingProps) {
  const classes = useStyles();
  return (
    <div className={`${classes.mainLoadingContainer} ${dark && classes.dark}`}>
      <Logo
        handColor={dark ? colors.primaryContrast : colors.primary}
        textColor={dark ? colors.primaryContrast : colors.darkTxtColor}
      />
      <p>{message ? message : "Redirigiendo al sistema de pagos de Handy"}</p>
    </div>
  );
}

export default Loading;
