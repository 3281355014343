import { BASE_URL } from "../services/config";

export const urlHelper = {
  isPrivatePage,
  isHeaderlessUrl,
  getHubUrl,
};

const privatePages = ["/historic", "/payment-details", "/user-details"];

function isPrivatePage(url: string): boolean {
  url = "/" + url.split("/")[1];
  return privatePages.indexOf(url) > -1 || url.startsWith("/payment-details");
}

const headerlessPages = ["/confirm-email", "/redirect", "/"];

function isHeaderlessUrl(url: string) {
  return headerlessPages.indexOf(url) > -1 ;
}

function getHubUrl() {
  return `${BASE_URL}/api/plexoHub`;
}
