import React from "react";
import { Button, Divider } from "@mui/material";
import { getContent } from "./content";
import { useHistory } from "react-router-dom";

import { errorHelper } from "../../../helpers/errorHelper";
import { PurchaseData, TransactionCallbackResponse } from "../../../store/config/types";
import { PaymentStatusSpanishEnum } from "../../../store/config/enums";
import { Download } from '../Icons/Download';
import { jsPDF } from "jspdf";
import LogoExportPath from '../Icons/Hand/export.png'

interface FeedbackBoxProps {
  errorCode: string;
  errorMessage?: string;
  purchaseData: PurchaseData | null;
  transactionCallbackResponse?: TransactionCallbackResponse | null;
  hideHandyFooter?: boolean;
  plexoError?: string | null;
  showActions?: boolean;
}

function FeedbackBox ({
  errorCode,
  errorMessage,
  purchaseData,
  transactionCallbackResponse,
  hideHandyFooter = false,
  plexoError,
  showActions = false
}: FeedbackBoxProps) {
  const history = useHistory();
  const dataDependentCodes = ["3"];

  let content, companyName, companyLogo;
  let companyUrl: undefined | string;

  if (purchaseData || dataDependentCodes.findIndex((ec) => ec === errorCode) < 0) {
    companyName = purchaseData?.client?.commerceName;
    companyLogo = purchaseData?.client?.commerceImageUrl;
    companyUrl = purchaseData?.clientSiteUrl;
    content = getContent(errorCode, companyName, companyUrl);
  } else {
    content = getContent("99", companyName, companyUrl);
  }

  const redirectToCompany = (url: string) => {
    window.location.href = url ? url : "";
  };

  const handleOnDownload = (content: any) => () => {
    const doc = new jsPDF();

    // Logo
    doc.addImage(LogoExportPath, "PNG", 10, 10, 50, 13)

    // Header
    doc.setFontSize(18);
    doc.setTextColor(51, 51, 51);
    doc.text("Handy Link - Detalle de comprobante", 10, 45);
    doc.setDrawColor(243, 243, 243);
    doc.setLineWidth(0.2);
    doc.line(10, 48, 200, 48);

    // Content
    doc.addImage(content.iconExportPath, "PNG", 10, 52, 10, 10)
    doc.setFontSize(16);
    doc.setTextColor(51, 51, 51);
    doc.text(content.errorTitle, 25, 59)
    doc.setTextColor(105, 105, 105);
    doc.setFontSize(14);
    let message = content.success
      ? content.contentText : errorMessage && errorMessage !== "" ? errorMessage : errorCode && errorCode.toString().indexOf("plexo") > -1
        ? errorHelper.getMerchantErrorMessage(Number(errorCode.toString().split("_")[1]))
        : content.errorSubTitle

    if ((errorCode === "0" || errorCode === "7" || errorCode === "12") && transactionCallbackResponse && transactionCallbackResponse.paymentCode) {
      message = `Para finalizar la compra, diríjase a un local de Redpagos e indique que va a pagar una cuenta de Handy. Deberá mencionar su cédula de identidad o el siguiente código de pago: ${transactionCallbackResponse.paymentCode}`
    }

    doc.text(message, 25, 68, { maxWidth: 160 })

    if (content.success) {
      doc.setFontSize(18);
      doc.setTextColor(51, 51, 51);
      doc.text("Datos de la compra", 10, 100);
      doc.line(10, 103, 200, 103);

      const lineHeight = 8
      let currentLine = 102;
      doc.setFontSize(13);
      if ((errorCode === "0" || errorCode === "7" || errorCode === "12" || errorCode === "13") && transactionCallbackResponse && transactionCallbackResponse.products) {
        transactionCallbackResponse.products.forEach(product => {
          currentLine += lineHeight
          doc.setTextColor(146, 146, 146);
          doc.text("Compra:", 10, currentLine)
          doc.setTextColor(105, 105, 105);
          doc.text(product?.name, 30, currentLine)

          currentLine += lineHeight
          doc.setTextColor(146, 146, 146);
          doc.text("Monto del artículo:", 10, currentLine)
          doc.setTextColor(105, 105, 105);
          doc.text(product?.amount.toString(), 52, currentLine)

          currentLine += lineHeight
          doc.setTextColor(146, 146, 146);
          doc.text("Cantidad:", 10, currentLine)
          doc.setTextColor(105, 105, 105);
          doc.text(product?.quantity.toString(), 32, currentLine)
        })

        currentLine += lineHeight
        doc.setTextColor(146, 146, 146);
        doc.text("Monto total:", 10, currentLine)
        doc.setTextColor(105, 105, 105);
        doc.text(`${transactionCallbackResponse.currencySymbol} ${transactionCallbackResponse.purchaseAmount}`, 38, currentLine)

        if (transactionCallbackResponse.issuer !== 'Sistarbanc') {
          currentLine += lineHeight
          doc.setTextColor(146, 146, 146);
          doc.text("Estado del pago:", 10, currentLine)
          doc.setTextColor(105, 105, 105);
          doc.text(PaymentStatusSpanishEnum[transactionCallbackResponse.status], 47, currentLine)
        }

        currentLine += lineHeight
        doc.setTextColor(146, 146, 146);
        doc.text("Fecha:", 10, currentLine)
        doc.setTextColor(105, 105, 105);
        doc.text(transactionCallbackResponse.purchaseLastModifiedDate, 27, currentLine)

        currentLine += lineHeight
        doc.setTextColor(146, 146, 146);
        doc.text("La compra fue realizada con:", 10, currentLine)
        doc.setTextColor(105, 105, 105);
        doc.text(transactionCallbackResponse.issuer, 72, currentLine)

        if (transactionCallbackResponse.issuer !== 'Pago después' && transactionCallbackResponse.issuer !== 'Redpagos' && transactionCallbackResponse.issuer !== 'Sistarbanc') {
          currentLine += lineHeight
          doc.setTextColor(146, 146, 146);
          doc.text("Cantidad de cuotas:", 10, currentLine)
          doc.setTextColor(105, 105, 105);
          doc.text(transactionCallbackResponse.installments.toString(), 52, currentLine)
        }
      }
      if ((errorCode === "0" || errorCode === "7" || errorCode === "9" || errorCode === "11" || errorCode === "12" || errorCode === "13") && transactionCallbackResponse && transactionCallbackResponse.purchaseCode) {
        currentLine += lineHeight
        doc.setTextColor(146, 146, 146);
        doc.text("Su identificador de compra en Handy es:", 10, currentLine)
        doc.setTextColor(105, 105, 105);
        doc.text(transactionCallbackResponse.purchaseCode, 95, currentLine)
      }
    }

    doc.save("comprobante-handy-link.pdf");
  };

  return (
    <div className="main-feedback-container">
      <div className={`top-feedback-box ${content.success ? "feedback-color-success" : "feedback-color-failed"}`}>
        <div>{content.icon}</div>
        <div className="feedback-header">
          <p className="feedback-header-title"><b>Resultado del pago</b></p>
          <p className="feedback-header-second-title"><b>{content.errorTitle}</b></p>
          <p className="feedback-header-subtitle">
            {errorMessage !== ""
              ? errorMessage
              : errorCode && errorCode.toString().indexOf("plexo") > -1
                ? errorHelper.getMerchantErrorMessage(Number(errorCode.toString().split("_")[1]))
                : content.errorSubTitle}
          </p>
        </div>
      </div>

      <div className="bottom-feedback-box">
        {content.companyLogo && <img src={companyLogo} alt={`Logo de ${companyName}`} />}
        <p className="title">{content.contentText}</p>
        {(errorCode === "0" || errorCode === "7" || errorCode === "12" || errorCode === "13") && (
          <div className="code">
            {transactionCallbackResponse && (
              <>
                {transactionCallbackResponse.paymentCode && (
                  <>
                    <p>Para finalizar la compra, diríjase a un local de Redpagos e indique que va a pagar una cuenta de Handy. Deberá mencionar su cédula de identidad o el siguiente código de pago: <b>{transactionCallbackResponse && transactionCallbackResponse.paymentCode}</b></p>
                  </>)
                }
                <div>
                  {transactionCallbackResponse && transactionCallbackResponse.products && transactionCallbackResponse.products.map((product, index) =>
                  (<div key={`product-${index}`} className="products">
                    <div key={`product-${index}-name`} className="line">Compraste: <b>{product?.name}</b></div>
                    <div key={`product-${index}-amount`} className="line">Monto del producto: <b>{product?.amount}</b></div>
                    <div key={`product-${index}-quantity`} className="line">Cantidad: <b>{product?.quantity}</b></div>
                  </div>)
                  )}
                </div>
                <div>
                  <div className="totalAmount">
                    Monto total: <strong>{transactionCallbackResponse && transactionCallbackResponse.currencySymbol + transactionCallbackResponse.purchaseAmount}</strong>
                  </div>
                </div>
                <div className="bottom-values">
                  {transactionCallbackResponse.issuer !== 'Sistarbanc' &&
                    <p>
                      Estado del pago: <strong>{transactionCallbackResponse && PaymentStatusSpanishEnum[transactionCallbackResponse.status]}</strong>
                    </p>
                  }
                  <p>
                    Fecha: <strong>{transactionCallbackResponse && transactionCallbackResponse.purchaseLastModifiedDate}</strong>
                  </p>
                  <p>
                    La compra fue realizada con: <strong>{transactionCallbackResponse && transactionCallbackResponse.issuer}</strong>
                  </p>
                  {transactionCallbackResponse.issuer !== 'Pago después' &&
                    transactionCallbackResponse.issuer !== 'Redpagos' &&
                    transactionCallbackResponse.issuer !== 'Sistarbanc' &&
                    <p>
                      Cantidad de cuotas: <strong>{transactionCallbackResponse && transactionCallbackResponse.installments}</strong>
                    </p>
                  }
                </div>
              </>
            )}
          </div>
        )}
        {(errorCode === "0" || errorCode === "7" || errorCode === "9" || errorCode === "11" || errorCode === "12" || errorCode === "13") && (
          <div className="code">
            {transactionCallbackResponse && transactionCallbackResponse.purchaseCode && (
              <div className="bottom-values">
                <p>Su identificador de compra en Handy es: <strong>{transactionCallbackResponse && transactionCallbackResponse.purchaseCode}</strong></p>
              </div>
            )}
          </div>
        )}
        {content.button && (
          <Button
            title="company-button"
            onClick={() => {
              if (errorCode === "0" || errorCode === "3" || errorCode === "7" || errorCode === "12" || errorCode === "13") {
                if (companyUrl !== undefined) {
                  redirectToCompany(companyUrl);
                }
              } else {
                history.push(`/details/${history.location.search}`);
              }
            }}
            color="primary"
            variant="contained"
            fullWidth
            disableElevation
          >
            {content.buttonTxt}
          </Button>
        )}
        {content.footer && (
          <div className="feedback-footer">
            {content.contentText !== "" && <Divider />}
            <p>{content.footerTxt}</p>
          </div>
        )}

        {showActions ? <div className="feedback-actions">
          {/*<button onClick={handleOnShare}>Compartir <Share /></button>*/}
          <button onClick={handleOnDownload(content)}>Descargar <Download /></button>
        </div> : null}
      </div>

      {/* {hideHandyFooter || !content.footer ? null : (
        <div className="feedback-logo">
          <Logo textColor={colors.darkTxtColor} handColor={colors.primary} />
        </div>
      )} */}
    </div>
  );
};

export default FeedbackBox;