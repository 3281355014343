import React from "react";
import AppBar from "@mui/material/AppBar";
import { Theme, IconButton } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Logo } from "../Logo";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { colors } from "../../../theme";
import { useHistory } from "react-router-dom";
import { urlVarsHelper } from "../../../helpers/urlVarsHelper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: colors.primaryContrast,
      padding: 0,
      marginLeft: "10px"
    },
    header: {
      display: 'flex',
      flexDirection: "row",

      "& .headerTextWrapper": {
        width: "100%",
        justifyContent: "flex-end"
      },

      "& .headerText": {
        "& p": {
          fontSize: 22
        },

        [theme.breakpoints.down('sm')]: {
          "& p": {
            fontSize: 12,
            marginTop: 7
          }
        }
      },

      "& .cancel": {
        cursor: 'pointer'
      }
    }
  })
);

interface CustomToolbarProps {
  url?: string;
}

function CustomToolbar({ url }: CustomToolbarProps) {
  const classes = useStyles();
  const history = useHistory();

  const goToRoot = () => {
    history.push(`/?sessionId=${urlVarsHelper.getUrlVars().sessionId}`);
  };

  const goToRedirect = () => {
    console.log("redirect");

    window.location.href = url ?? "";
  };

  if (!url) {
    var clientSiteUrl = window.localStorage.getItem("clientSiteUrl");
    if (clientSiteUrl) url = clientSiteUrl;
  }

  return (
    <AppBar position="static" elevation={0} className={`${classes.header} toolbar`}>
      <div>
        <div className="toolbar-items">
          <div className="toolbar-logo" title="logo" onClick={goToRoot}>
            <Logo />
          </div>
        </div>
        <div className="headerTextWrapper">
          <div className="headerText">
            <p>Bienvenido a Handy Link</p>
          </div>
          {url ? (
            <div className="cancel" title="cancel" onClick={goToRedirect}>
              <p>Cancelar y volver</p>
              <IconButton className={classes.button}>
                <HighlightOffIcon />
              </IconButton>
            </div>
          ) : null}
        </div>
      </div>
    </AppBar>
  );
}

export default CustomToolbar;
