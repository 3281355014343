import * as React from "react";

function PendingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 460 460"
      xmlSpace="preserve"
      width={72} 
      height={72}
    >
      <path d="M230 6C106.5 6 6 106.5 6 230s100.5 224 224 224 224-100.5 224-224S353.5 6 230 6zm0 412c-103.7 0-188.1-84.3-188.1-188S126.3 41.9 230 41.9 418 126.3 418 230s-84.3 188-188 188z" />
      <path d="M308.7 125.1c-2.7-1.9-6.2-2.3-9.3-1.2-.2.1-19 6.9-36.5 6.9-8.7 0-15.4-1.8-19.2-5.1-7.7-6.6-18.6-9.9-32.4-9.9-21.1 0-42.6 7.8-43.5 8.1-4 1.5-6.7 5.3-6.7 9.5v202.7c0 5.6 4.5 10.1 10.1 10.1s10.1-4.5 10.1-10.1V221.4c6.5-2.3 17.6-5.5 28.6-5.5 8.8 0 15.7 2.1 20.4 6.2 8.5 7.5 19.9 11.3 33.8 11.3 21.5 0 42.1-9.3 43-9.7 3.6-1.6 5.9-5.2 5.9-9.2v-81.1c0-3.3-1.6-6.4-4.3-8.3zm-16 82.5c-6.5 2.3-17.6 5.5-28.6 5.5-8.8 0-15.7-2.1-20.4-6.2-8.5-7.5-19.9-11.3-33.8-11.3-10.5 0-20.8 2.2-28.6 4.5v-59.3c7.1-2 18.8-4.8 29.9-4.8 8.7 0 15.4 1.8 19.2 5.1 7.7 6.6 18.6 9.9 32.4 9.9 10.8 0 21.7-2 29.9-4.1v60.7z" />
    </svg>
  );
}

export default PendingIcon;
