import { combineReducers, compose } from "redux";
import { purchasesReducer } from "../reducers/purchases";
import { instrumentsReducer } from "../reducers/instruments";
import { cognitoReducer } from "../reducers/cognito";
import { transactionsReducer } from "../reducers/transactions";
import { connectRouter } from "connected-react-router";
import ReactGA from "react-ga";
import { configureStore } from "@reduxjs/toolkit";
import { createBrowserHistory} from "history";
import signalRMiddleware from "../middlewares/signalRMiddleware";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

ReactGA.initialize("UA-83289189-7", { testMode: true });

const gaTrackingMiddleware = () => (next: any) => (action: any) => {
  if (action.type === "@@router/LOCATION_CHANGE") {
    const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;
    trackPage(nextPage);
  }
  return next(action);
};

const trackPage = (page: any) => {
  ReactGA.pageview(page);
};

export const history = createBrowserHistory();

export const rootReducer = combineReducers({
  router: connectRouter(history),
  purchases: purchasesReducer,
  instruments: instrumentsReducer,
  cognito: cognitoReducer,
  transactions: transactionsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

// Automatically adds the thunk middleware and the Redux DevTools extension
export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    const middleware = getDefaultMiddleware().concat(gaTrackingMiddleware, signalRMiddleware);
    return middleware;
  }
});